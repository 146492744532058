<div class="video-player-wrapper" *ngIf="ready">
  <vg-player (onPlayerReady)="videoPlayerInit($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video #media [vgMedia]="$any(media)" [src]="currentVideo.src" id="singleVideo" preload="auto" crossorigin>
    </video>
  </vg-player>
  <ion-list class="player-list">
    <ion-item *ngFor="let vdo of videoItems; let i = index" (click)="startPlaylistVdo(vdo, i)" [attr.data-index]="i"
      [class.selected]="vdo === currentVideo">
      <ion-label slot="start">{{ vdo.name }}</ion-label>
      <!-- <ion-button *ngIf="!downloadedFiles[i]" slot="end" (click)="download(vdo, i)">Download</ion-button>
      <ion-button *ngIf="downloadedFiles[i]" slot="end">Remove</ion-button> -->
      <!-- (click)="removeAlert(video.id)" -->
      <ion-button color="danger" *ngIf="downloadedFiles[i]" fill="clear" size="small" slot="end"
        (click)="removeVideoDownload(vdo, i)">
        <ion-icon slot="start" color="primary" name="cloud-done"></ion-icon>
        REMOVE
      </ion-button>
      <ion-button *ngIf="!downloadedFiles[i]" (click)="download(vdo, i)" fill="clear" size="small" slot="end">
        <ion-icon slot="icon-only" color="primary" name="cloud-download-outline"></ion-icon>
      </ion-button>

    </ion-item>
  </ion-list>
</div>
