// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useEmulators: true,
  firebase: {
    projectId: 'gotgametech-e3086',
    appId: '1:151107521564:web:a597a23d35722a858623d9',
    databaseURL: 'https://gotgametech-e3086.firebaseio.com',
    storageBucket: 'gotgametech-e3086.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCcutPIEZav9KqfSTRhCxnWJd32lDe6ugI',
    authDomain: 'gotgametech-e3086.firebaseapp.com',
    messagingSenderId: '151107521564',
    measurementId: 'G-8EQQXC2V4L',
  },
  app: 'elknut',
  appName: 'ElkNut',
  pro: 'Paul Medel',
  type: 'Elk',
  vimeoAccessToken: 'b6e65954444e54d5b6591323b596e279',
  gplus: {
    webClientId: '151107521564-c5n5euerjd9rr27n3n4kgqtqc89b3v5t.apps.googleusercontent.com'
  },
  products: {
    pro: 'com.gotgametech.elknut.pro_unlimited',
    subscription: 'elknut_pro_annual',
    proStripeId: 'prod_LyQLfqK2In9aM5',
    revenueCatPro: 'ElkNut Pro'
  },
  wordpressTagId: '44',
  wordpressTagLimit: '5',
  stripePublishKey: 'pk_live_51LGTKzD2A6yqfXJ2kbKIkubQcc29wbYTLOIybn1TSFlJmWVe903jibqXxIi2R3n9jFI0KcR92jzwBxk0wHK6tTLX00JFrwA4Rr',
  // stripePublishKey: 'pk_test_51LGTKzD2A6yqfXJ2haKqB681jWqV4Hfqynm8ok5ui1Qj48lQmVDo9v5QVtrg7ZZc6kZ1m1w04UKNrtwpukgOBmzz00J6e6H5d1',
  revenueCatKey: 'sk_SnzlbLbghYAThNdoSCUqUVEmYVhqR',
  revenueCatAndroidKey: 'goog_guDAPfTluyiiyvxseAUcbPcJusP',
  revenueCatIosKey: 'appl_gMvwJpAsXAaUVbRSPRTvBHJZNLE',
  revenueCatStripeKey: 'strp_VscsEOwIgKaeCFdqtTwLfNvzmcC',
  functionApiUrl: 'https://us-central1-gotgametech-e3086.cloudfunctions.net/main'
};

/* http://localhost:5001/gotgametech-e3086/us-central1/main
https://us-central1-gotgametech-e3086.cloudfunctions.net/main
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
