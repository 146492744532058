/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { UserAccount } from '../models/user-account.model';
import {
  query, collection, where, getDocs, Firestore, collectionData, onSnapshot, doc, setDoc,
  addDoc, deleteDoc
} from '@angular/fire/firestore';
import {
  getStorage, ref, uploadBytes, UploadResult, getDownloadURL,
  deleteObject, listAll, list
} from '@angular/fire/storage';
import { Observable, Observer } from 'rxjs';
import { Auth, getAuth, updateProfile } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private key = 'users';
  constructor(private fs: Firestore, private auth: Auth,
    private toastController: ToastController) { }

  async getCurrentUserToken() {
    return this.auth.currentUser.getIdToken();
  }

  async getCurrentUser() {
    return this.auth.currentUser;
  }

  async updateUser(user: UserAccount, file?: File) {
    const auth = getAuth();
    // const result = await this.uploadFile(auth.currentUser.uid, file);
    // console.log('updateUser.uploadFile.result', result);
    // user.photoURL = result;
    let updated = false;
    updateProfile(auth.currentUser, {
      displayName: user.displayName
      // photoURL: result
    }).then(() => {
      // Profile updated!
      this.openToast('User Account Info Updated');
      // console.log('updateUser', result);
      updated = true;
    }).catch((error) => {
      // An error occurred
      this.openToast(`Account Error ${error}`);
      console.log('updateUser.error', error);
      updated = false;;
    });
    return updated;
  }

  dataURLtoFile(dataurl, filename) {

    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  private uploadFile(id: string, file: File) {
    const storage = getStorage();
    const name = `${this.key}/${id}/${file.name}`;
    const storageRef = ref(storage, name);
    let url = '';
    return uploadBytes(storageRef, file).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then((downloadUrl) => {
        url = downloadUrl;
        console.log('Download url', url);
        return url;
      });
      console.log('Uploaded a blob or file!', snapshot);
    });
  }

  private openToast(message: string) {
    this.toastController.create({
      color: 'dark',
      duration: 5000,
      message
    }).then((toastData) => {
      toastData.present();
    });
  }



  // getUserDoc(id) {
  //   return this.angularFirestore
  //     .collection('users')
  //     .doc(id)
  //     .valueChanges()
  // }

  // getUserList() {
  //   return this.angularFirestore
  //     .collection("users")
  //     .snapshotChanges();
  // }

  // createUser(user: User) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.angularFirestore
  //       .collection("users")
  //       .add(user)
  //       .then(response => { console.log(response) }, error => reject(error));
  //   });
  // }

  // deleteUser(user) {
  //   return this.angularFirestore
  //     .collection("users")
  //     .doc(user.id)
  //     .delete();
  // }

  // updateUser(user: User, id) {
  //   console.log('updateUser', id, user);
  //   return this.angularFirestore
  //     .collection("users")
  //     .doc(id)
  //     .update({
  //       displayName: user.displayName,
  //     });
  // }

}
