<ion-card *ngFor="let product of products" class="media-card">
  <ion-card-header>
    <ion-card-title>
      <img [src]="product?.images[0]" />
      {{product?.name}}
    </ion-card-title>
    <ion-card-subtitle>{{product?.description}}</ion-card-subtitle>
    <ion-card-content>
      <div>Pro Subscription gives access to premium content such as audio, video, tips etc, within the app.</div>
      <div>${{product?.price?.unit_amount / 100 | number}} /year</div>
    </ion-card-content>
  </ion-card-header>
  <ion-footer>
    <ion-row>
      <ion-col>
        <!-- && !isCapacitor() -->
        <ion-button [disabled]="disableSubscribe" *ngIf="!hasActiveClaim() && !isCapacitor()"
          (click)="onClickSubscribe(product.price.id)" color="light" expand="block">
          <ion-icon *ngIf="loading" slot="start" class="icn-spinner" name="refresh-outline"></ion-icon>
          Subscribe w/Stripe
        </ion-button>
        <ion-button *ngIf="!hasActiveClaim() && isCapacitor()" (click)="manageCapPurchase()" color="light"
          expand="block">
          <ion-icon *ngIf="loading" slot="start" class="icn-spinner" name="refresh-outline"></ion-icon>
          <span *ngIf="isIos()">Subscribe w/Appstore</span>
          <span *ngIf="isAndroid()">Subscribe w/Playstore</span>
        </ion-button>
        <!-- && !isCapacitor() -->
        <ion-button *ngIf="hasActiveClaim() && (currentClaim.provider === provider.stripe)" color="success"
          expand="block">
          Subscribed w/Stripe
        </ion-button>
        <ion-button *ngIf="hasActiveClaim() && (currentClaim.provider === provider.appstore)" color="success"
          expand="block">

          Subscribed w/AppStore
        </ion-button>
        <ion-button *ngIf="hasActiveClaim() && (currentClaim.provider === provider.playstore)" color="success"
          expand="block">
          Subscribed w/Playstore
        </ion-button>
        <ion-button (click)="onClickRefresh()" color="light" expand="block">
          Refresh Subscriptions
        </ion-button>
        <ion-button *ngIf="!isProduction" (click)="clearClaim()" color="light" expand="block">
          Clear Claims
        </ion-button>
        <ion-button *ngIf="hasActiveClaim() && (currentClaim.provider === provider.stripe)"
          (click)="onClickMemberShipPortal()" color="primary" expand="block" type="submit">
          <ion-icon *ngIf="loading" slot="start" class="icn-spinner" name="refresh-outline"></ion-icon>
          Manage Stripe Subscriptions
        </ion-button>
      </ion-col>
    </ion-row>

    <!-- <ion-row>
      <app-cap-purchases *ngIf="isCapacitor()"></app-cap-purchases>
    </ion-row> -->
  </ion-footer>
</ion-card>