import { Component, Input, OnInit } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { VideoModel, VideoItem, VimeoVideo } from '../../models/video.model';
import { VideoService } from '../../services/video.service';
// import { VgApiService } from '@videogular/ngx-videogular/core';
// import { VgAPI } from 'videogular2/core';
// import { } from '@videogular/ngx-videogular';
@Component({
  selector: 'app-videogular-player',
  templateUrl: './videogular-player.component.html',
  styleUrls: ['./videogular-player.component.scss'],
})
export class VideogularPlayerComponent implements OnInit {
  @Input() content: ContentModel;
  @Input() videos: VideoModel[];
  videoItems: VideoItem[] = [] as VideoItem[];
  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data: any;
  ready = false;

  constructor(private videoSvc: VideoService) {
  }
  ngOnInit(): void {
    // console.log('VideogularPlayerComponent.content', this.content);
    // console.log('VideogularPlayerComponent.videos', this.videos);
    this.loadVideos();
  }

  async loadVideos() {
    this.videos.forEach(async video => {
      const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
      // console.log('vimeo', vimeo);
      const src = this.videoSvc.getHdVideoUrl(vimeo.files);
      // console.log('vimeo.src', src);
      const vid: VideoItem = {
        name: video.name,
        src: src.link,
        type: 'video/mp4'
      };
      // console.log('video.item', vid);
      await this.videoItems.push(vid);
      this.currentVideo = this.videoItems[this.activeIndex];
      // console.log('videoItems', this.videoItems);
      this.ready = true;
    });
  }

  videoPlayerInit(data: any) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
    this.data.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
  nextVideo() {
    this.activeIndex++;
    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }
    this.currentVideo = this.videoItems[this.activeIndex];
  }
  initVdo() {
    this.data.play();
  }
  startPlaylistVdo(item: any, index: number) {
    this.activeIndex = index;
    this.currentVideo = item;
  }

}
