/* eslint-disable @typescript-eslint/naming-convention */
import { SafeUrl } from '@angular/platform-browser';
import { DocStatus } from './doc.status.model';

export class VideoModel {
  id?: string;
  _id?: string;
  _rev?: string;
  name: string;
  app?: string;
  status: DocStatus;
  updated_at?: Date;
  vimeoId?: string;
  contentId: string;
  thumbnail?: string;
}

export interface VideoItem {
  name: string;
  src: string;
  type: string;
}

export interface VimeoVideo {
  _id?: string;
  _rev?: string;
  uri: string;
  name: string;
  description: string;
  type: string;
  link: string;
  duration: number;
  width: number;
  language: string;
  height: number;
  embed: {
    html: string;
  };
  pictures: {
    uri: string;
    sizes: Array<VideoThumbnailSize>;
  };
  files: Array<VideoFile>;
  download: Array<VideoFile>;
}

export interface VideoThumbnailSize {
  width: number;
  height: number;
  link: string;
  link_with_play_button: string;
}

export interface VideoFile {
  quality: string;
  type: string;
  width: number;
  height: number;
  link: string;
  created_time: Date;
  fps: number;
  size: number;
  md5: string;
  public_name: VideoFileSize;
}

export enum VideoFileSize {
  seven = '720p',
  two = '240p',
  ten = '1080p',
}
