/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { AudioModel } from '../../models/audio.model';
import { ContentModel } from '../../models/content.model';
import { PracticeAudioModel } from '../../models/practice-audio.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { AlertController } from '@ionic/angular';

import { VoiceRecorder, VoiceRecorderPlugin, RecordingData, GenericResponse } from 'capacitor-voice-recorder';
import { environment } from 'src/environments/environment';
import { PracticeAudioService } from '../../services/practice-audio.service';

@Component({
  selector: 'app-audio-recorder-ios',
  templateUrl: './audio-recorder-ios.component.html',
  styleUrls: ['./audio-recorder-ios.component.scss'],
})
export class AudioRecorderIosComponent implements OnInit {
  @Input() content: ContentModel;
  practices: PracticeAudioModel[];
  audioModel: AudioModel[];
  fileName: string;

  file: File;
  currentFileName: string;
  recording = false;
  url;
  // Stopwatch
  counter = 0;
  timerRef;
  running = false;

  // Error Message
  errorMessage = '';

  constructor(private domSanitizer: DomSanitizer, private location: Location,
    private alertController: AlertController, private practiceSvc: PracticeAudioService) {
  }

  ngOnInit() {
    console.log('AudioRecorderAndroidComponent.this.content.name', this.content);
    // this.buildFileName();
    this.getPermissions();
  }

  getFileName(fileExtension) {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const contentName = this.content.name.replace(' ', '.').toLowerCase();
    const fileName = `${environment.app}-${contentName}-${year}${month}${date}-${this.getRandomString()}.${fileExtension}`;
    console.log('getFileName.fileName', fileName);
    return fileName;
    // return 'elknut-' + contentName + '-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;
  }

  defaultName() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const time = d.getTime();
    const contentName = this.content.name.replace(' ', '.').toLowerCase();
    return `${contentName}-${year}.${month}.${date}-${time}`;
  }

  getRandomString() {
    if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
      const a = window.crypto.getRandomValues(new Uint32Array(3));
      let token = '';
      for (let i = 0, l = a.length; i < l; i++) {
        token += a[i].toString(36);
      }
      return token;
    } else {
      return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
  }

  async getPermissions() {
    console.log('Get Audio File Permissions');
    try {
      const hasPermission = await VoiceRecorder.requestAudioRecordingPermission();
      if (!hasPermission) {
        this.errorMessage = ' No Microphone Permission. Please Fix';
      }
      console.log(hasPermission);
    } catch (error) {
      this.errorMessage = error;
    }
  }

  async startRecording() {
    this.clearTimer();
    const status = await VoiceRecorder.getCurrentStatus();
    if (status.status !== 'NONE') {
      await VoiceRecorder.stopRecording();
    }
    console.log('startRecording.status', status);
    await VoiceRecorder.startRecording();
    this.currentStatus();
    this.recording = true;
    this.startTimer();
  }

  async stopRecording() {
    const recording = await VoiceRecorder.stopRecording();
    this.makeBlob(recording);
    // audio/aac;
    // this.url = new Audio(`data:${recording.value.mimeType};base64,${recording.value.recordDataBase64}`);
    // console.log('audioRef', audioRef);
    this.recording = false;
    this.startTimer();
  }

  async pauseRecording() {
    const recording = await VoiceRecorder.pauseRecording();
  }

  async onClickSave() {
    const alert = await this.alertController.create({
      header: 'Save Recording',
      buttons: [
        {
          text: 'BACK',
          role: 'cancel',
          handler: () => { console.log('Canceled'); }
        },
        {
          text: 'OK',
          role: 'save',
          handler: (input) => { this.save(input[0]); }
        }
      ],
      inputs: [
        {
          placeholder: 'Name',
          value: this.defaultName()
        }
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  async save(fileName: string) {
    const practice: PracticeAudioModel = {
      name: fileName,
      app: environment.app,
      updated_at: Date.now().toString(),
      content: this.content.id,
      status: 'published'
    };
    console.log('Saving.File.Ios', fileName, practice, this.file);
    this.practiceSvc.addPractice(practice, this.file).then(async () => {
      // const result = await this.fileSystem.removeFile(this.fileSystem.externalApplicationStorageDirectory, this.currentFileName);
      // console.log('File Remove Result', result);
    });
    this.location.back();
  }

  currentStatus() {
    // const recording = await VoiceRecorder.getCurrentStatus();
    VoiceRecorder.getCurrentStatus().then(status => {
      console.log('currentStatus', status);
    });
  }

  async makeBlob(recording: RecordingData) {
    const blob = this.practiceSvc.b64toBlob(recording.value.recordDataBase64, 'audio/aac');
    console.log('recording.value.blob', blob);
    this.currentFileName = this.getFileName('m4a');
    const myBlob: any = Object.assign(blob) as File;
    myBlob.name = this.currentFileName;
    myBlob.lastModified = new Date();
    this.file = myBlob;
    this.url = URL.createObjectURL(blob);
    console.log('makeBlob.file', this.file);
    console.log('makeBlob.url', this.url);
  }

  startTimer() {
    this.running = !this.running;
    if (this.running) {
      // this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = +((Date.now() - startTime) / 1000).toFixed(2);
      });
    } else {
      // this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  clearTimer() {
    this.running = false;
    // this.startText = 'Start';
    this.counter = 0;
    clearInterval(this.timerRef);
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  getImage(id: string) {
    return '/assets/img/' + id + '/Audio@1x.png';
  }

}
