/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { from, Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { httpsCallable, getFunctions, connectFunctionsEmulator, HttpsCallableOptions } from '@angular/fire/functions';
import { getApp } from '@angular/fire/app';
import { AuthService } from 'src/app/core/services/auth.service';
import { StripeProduct, StripePrice } from 'src/app/core/models/stripe.products.model';
import { Prices, Price, RootPrice } from 'src/app/core/models/stripe.price.model';
import {
  getStripePayments, getProducts, getCurrentUserSubscriptions, getCurrentUserPayments,
  createCheckoutSession, onCurrentUserSubscriptionUpdate
} from '@stripe/firestore-stripe-payments';
import { Firestore, collection, getDoc, getDocs } from '@angular/fire/firestore';
import { StripeSubscriptionModel } from '../models/stripe.subscription.model';
import { AppInfoService } from './app-info.service';

// product-list

@Injectable({
  providedIn: 'root'
})
export class StripePaymentService {

  constructor(private http: HttpClient, private auth: AuthService, private fs: Firestore,
    private appInfoSvc: AppInfoService) {
    // if (environment.useEmulators) {
    //   const functions = getFunctions(getApp());
    //   connectFunctionsEmulator(functions, 'localhost', 5001);
    // }
  }

  // async listProducts(): Promise<RootProduct> {
  //   const url = `api/product-list`;
  //   const fbt = await this.currentUserToken();
  //   const body: any = {
  //     token: environment.stripePublishKey,
  //     fbt
  //   };
  //   return this.http.post<RootProduct>(url, JSON.stringify(body)).toPromise();
  // }

  getStripePayments() {
    const app = getApp();
    const payments = getStripePayments(app, {
      productsCollection: 'products',
      customersCollection: 'customers',
    });
    return payments;
  }

  async getStripePrices(productId) {
    let prices: StripePrice[] = [] as StripePrice[];
    const d = collection(this.fs, 'subscriptions', productId, 'prices');
    const querySnapshot = await getDocs(d);
    querySnapshot.forEach(price => {
      let p = price.data() as StripePrice;
      p.id = price.id;
      prices.push(p);
    });
    return prices;
  }


  async getStripeProducts() {
    let products: StripeProduct[] = [] as StripeProduct[];
    const d = collection(this.fs, 'subscriptions');
    const querySnapshot = await getDocs(d);
    querySnapshot.forEach(product => {
      let p = product.data() as StripeProduct;
      console.log('getStripeProducts:', p);
      p.id = product.id;
      if (p.metadata?.app === environment.app) {
        products.push(p);
      }

    });
    return products;
  }

  async getStripeCustomerSubscriptions(uid: string) {
    let subscriptions: any[] = [] as any[];
    const d = collection(this.fs, 'customers', uid, 'subscriptions');
    const querySnapshot = await getDocs(d);
    querySnapshot.forEach(sub => {
      let p = sub.data();
      p.id = sub.id;
      subscriptions.push(p);
    });
    return subscriptions;
  }

  async getStripeCheckoutSession(priceId) {
    const payments = this.getStripePayments();
    const session = await createCheckoutSession(payments, {
      price: priceId,
    });
    return session;
  }

  async getStripeCurrentUserSubscriptions() {
    const payments = this.getStripePayments();
    // console.log('getStripeCurrentUserSubscriptions.payments', payments);
    return getCurrentUserSubscriptions(payments);
  }

  async getStripeCurrentUserPayments() {
    const payments = this.getStripePayments();
    return getCurrentUserPayments(payments);
  }

  // onCurrentUserSubscriptionUpdate() {
  //   const payments = this.getStripePayments();
  //   onCurrentUserSubscriptionUpdate(
  //     payments,
  //     (snapshot) => {
  //       for (const change in snapshot.changes) {
  //         if (change.type === 'added') {
  //           console.log(`New subscription added with ID: ${change.subscription.id}`);
  //         }
  //       }
  //     }
  //   );
  // }

  async listPrices(price?: string): Promise<RootPrice> {
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/product-price/${price}`);
    const fbt = await this.currentUserToken();
    const body: any = {
      token: environment.stripePublishKey,
      fbt
    };
    return lastValueFrom(this.http.post<RootPrice>(url, JSON.stringify(body)));
  }

  // async getStripeSubscriptionPrice(id: string) {
  //   let prices: StripePrice[] = [] as StripePrice[];
  //   const p = collection(this.fs, 'subscriptions', id, 'prices');
  //   const pSnapshot = await getDocs(p);
  //   pSnapshot.forEach(pr => {
  //     let price: StripePrice = pr.data() as StripePrice;
  //     price.id = pr.id;
  //     prices.push(price);
  //   });
  //   return prices;
  // }

  // async getSubs() {
  //   const subs: StripeSubscriptionModel[] = [] as StripeSubscriptionModel[];
  //   const q = collection(this.fs, 'subscriptions');
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach(d => {
  //     const sub = d.data();
  //     sub.id = d.id;
  //     // console.log('sub', sub);
  //     subs.push(sub as StripeSubscriptionModel);
  //     // console.log('subs', subs);
  //   });
  //   return subs;
  // }

  // async getAvailableStripeSubscriptions() {
  //   const subscriptions = await this.getSubs();
  //   const result = subscriptions.filter((sub) => sub.stripe_metadata_app === environment.app);
  //   console.log('getAvailableStripeSubscriptions', result);
  //   return result;
  // }

  async getCheckoutProductUrl(priceId) {
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/create-customer-checkout`);
    const fbt = await this.currentUserToken();
    const body: any = {
      token: environment.stripePublishKey,
      fbt,
      priceId
    };
    return lastValueFrom(this.http.post<any>(url, JSON.stringify(body)));
  }

  private async currentUserToken() {
    const token = await this.auth.currentUser().getIdToken();
    return token;
  }
}

