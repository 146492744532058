import { Component, OnInit } from '@angular/core';
import { AppInfoService } from '../../services/app-info.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {

  constructor(private appInfoSvc: AppInfoService) { }

  ngOnInit() { }

  openUrl(url: string) {
    this.appInfoSvc.openUrl(url);
  }

}
