/* eslint-disable eqeqeq */
import { Component, Input, OnInit } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { TierType } from '../../models/tier.types.model';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-tip-card-preview',
  templateUrl: './tip-card-preview.component.html',
  styleUrls: ['./tip-card-preview.component.scss'],
})
export class TipCardPreviewComponent implements OnInit {
  @Input() content: ContentModel;
  tier = TierType;
  constructor(private contentSvc: ContentService) { }

  ngOnInit() { }

  isItemPro(): boolean {
    return this.contentSvc.isItemPro(this.content);
  }

  getImage(id: string) {
    return '/assets/img/' + id + '/Card@2x.png';
  }
}
