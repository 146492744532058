import { Injectable } from '@angular/core';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';
import { Directory, DownloadFileOptions, DownloadFileResult, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { CapacitorHttp, HttpOptions, Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  videoFolderKey = 'videos';
  audioFolderKey = 'audio';
  constructor(private file: File, private http: HttpClient) { }

  async downloadVideoFile(fileName: string, link: string) {
    // kc15uk92gk0a6tb8ink kc15uk92gk0a6tb8ink.mp4
    // kc15h63zj4kfhp8mgsg
    console.log('download.downloadFile', fileName, link);
    await this.file.createDir(this.file.dataDirectory, this.videoFolderKey, true);
    const folderPath = `${this.file.dataDirectory}${this.videoFolderKey}`;
    const filePath = `${folderPath}/${fileName}`;
    console.log('download.service.getFile.filePath', filePath);
    // let result: WriteFileResult = {} as WriteFileResult;
    const blob = await lastValueFrom(this.http.get(link, { responseType: 'blob' }));
    console.log('blob', blob);
    return await this.writeFile(blob, folderPath, fileName);
  }

  async downloadAudioFile(fileName: string, link: string) {
    // kc15uk92gk0a6tb8ink kc15uk92gk0a6tb8ink.mp4
    // kc15h63zj4kfhp8mgsg
    console.log('downloadAudioFile.downloadFile', fileName, link);
    await this.file.createDir(this.file.dataDirectory, this.audioFolderKey, true);
    const folderPath = `${this.file.dataDirectory}${this.audioFolderKey}`;
    const filePath = `${folderPath}/${fileName}`;
    console.log('download.service.getFile.filePath', filePath);
    const blob = await lastValueFrom(this.http.get(link, { responseType: 'blob' }));
    console.log('blob', blob);
    const file = await this.writeFile(blob, folderPath, fileName);
    console.log('blob', blob);
    return await this.writeFile(blob, folderPath, fileName);
    // const options: DownloadFileOptions = {
    //   headers: { 'Content-Type': 'application/json' },
    //   method: 'POST',
    //   url: link,
    //   path: filePath
    // };
    // return await Filesystem.downloadFile(options);
  }

  private async writeFile(blob: Blob | string, folderPath: string, fileName: string) {
    console.log('writing Filesystem.writeFile');
    const createFile = await this.file.createFile(folderPath, fileName, true);
    const writeFile = await this.file.writeFile(folderPath, fileName, blob, { replace: true });
    console.log('Filesystem.written', createFile, writeFile);
    return writeFile;
    // this.file.createFile(folderPath, fileName, true).then(() => {
    //   this.file.writeFile(folderPath, fileName, blob).then((file) => {
    //     console.log('file created', file);
    //   });
    // });
    // const filePath = `${folderPath}/${fileName}`;
    // const directory = await this.file.getDirectory(folderPath);
    // return this.getFile(await this.file.getFile(await this.file.getDirectory(folderPath), fileName, true));
    // await this.blobToBase64
    // // const b64 = await this.blobToBase64(blob as Blob) as string;
    // // console.log('writing Filesystem.writeFile.data', b64);
    // // const file = await Filesystem.writeFile({
    // //   path: filePath,
    // //   data: Capacitor.isNativePlatform() ? b64 : blob as Blob,
    // //   recursive: true
    // // });
    // // console.log('Filesystem.written', file);
    // return file;
  }

  async removeDownloadVideoFile(filename: string) {
    const path = `${this.file.dataDirectory}${this.videoFolderKey}`;
    return await this.file.removeFile(path, filename);
  }

  async removeDownloadAudioFile(filename: string) {
    const path = `${this.file.dataDirectory}${this.audioFolderKey}`;
    return await this.file.removeFile(path, filename);
  }

  async listVideoFiles() {
    let files: Entry[] = [] as Entry[];
    try {
      files = await this.file.listDir(this.file.dataDirectory, this.videoFolderKey);
      console.log('listVideoFiles.files', files);
    } catch (error) {
      console.log('listVideoFiles.error', error);
    }
    // const files = await this.file.listDir(this.file.dataDirectory, this.videoFolderKey);
    return files;
  }

  async getFile(file: Entry) {
    const path = `${this.file.dataDirectory}${this.videoFolderKey}`;
    console.log('getFile.path', file);
    const f = await Filesystem.getUri({
      path: file.fullPath,
      directory: Directory.Data
    });
    console.log('getFile.f', f);
    return f;
  }
  async getAudioFileBase64(fileName: string) {
    const file = await this.getAudioFile(fileName);
    const f = await Filesystem.readFile({
      path: file.nativeURL,
      // directory: Directory.Data
    });
    const dataUri = `data:audio/mp3;base64,${f.data}`;
    console.log('getAudioFileBase64', dataUri);
    return dataUri;
  }

  async getAudioFile(fileName: string) {
    const path = `${this.file.dataDirectory}${this.audioFolderKey}`;
    const folder = await this.file.resolveDirectoryUrl(path);
    return await this.file.getFile(folder, fileName, {});
  }

  async downloadedVideoFile(fileName: string) {
    const files = await this.listVideoFiles();
    if (files.length) {
      const filtered = files.filter((file) => file.name === fileName);
      console.log('downloadedVideoFile.filtered', filtered);
      return filtered[0];
    } else {
      return {} as Entry;
    }

  }

  async convertFile(file: string) {
    const path = `${this.file.dataDirectory}${this.videoFolderKey}`;
    const f = this.file.readAsArrayBuffer(path, file).then((buffer) => {
      console.log('convertFile.buffer', buffer);
      return buffer;
    });
    // return this.file.readAsDataURL(path, file);
    // console.log('convertFile.file', file);
    console.log('convertFile.f', await f);

  }

  // private blobToBase64(blob: Blob) {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader();

  //     // reader.readAsArrayBuffer(blob);
  //     // reader.onloadend = () => {
  //     //   let buffer = reader.result;

  //     //   // Converting ArrayBuffer to Base64 string
  //     //   let base64data = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));

  //     //   // Logging the Base64 encoded string
  //     //   console.log('Base64 encoded:', base64data);
  //     // }
  //     // });
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.blobToBase64(blob);
  //   });
  // }

  // private async blobToBase64(blob: Blob) {
  //   const bufferPromise = await blob.arrayBuffer();
  //   // const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(bufferPromise)));
  //   const base64String = btoa(blob.);
  //   console.log('blobToBuffer', base64String);
  //   return base64String;
  // }

  private async btb64(blob: Blob) {
    const bufferPromise = await blob.arrayBuffer();
    const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(bufferPromise)));
    console.log('blobToBuffer', base64String);
    return base64String;
  }

  private async blobToBase64(blob: Blob, fileName: string) {
    const create = await this.file.createFile(this.file.dataDirectory, fileName, true);
    const write = await this.file.writeFile(this.file.dataDirectory, fileName, blob);
    return write;
    // this.file.createFile(this.file.dataDirectory, fileName, true).then(() => {
    //   this.file.writeFile(this.file.dataDirectory, fileName, blob).then(() => {
    //     console.log('file created');
    //   });
    // })
    // const file = new Promise((onSuccess, onError) => {
    //   try {
    //     const reader = new FileReader();
    //     reader.onload = function () {
    //       onSuccess(this.result);
    //     };
    //     reader.readAsDataURL(blob);
    //   } catch (e) {
    //     onError(e);
    //   }
    // });
    // const result = await file;
    // console.log('blobToBase64', result);
    // return result;
  }

}
