import { Component, OnInit, ViewChild } from '@angular/core';
import { WordpressPostModel } from '../../models/wordpress.post.model';
import { AppInfoService } from '../../services/app-info.service';
import { WordpressService } from '../../services/wordpress.service';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { Platform, isPlatform } from '@ionic/angular';

@Component({
  selector: 'app-listed-wordpress-items',
  templateUrl: './listed-wordpress-items.component.html',
  styleUrls: ['./listed-wordpress-items.component.scss'],
})
export class ListedWordpressItemsComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  posts: WordpressPostModel[] = [] as WordpressPostModel[];
  currentPost: WordpressPostModel;
  constructor(private wordpressSvc: WordpressService, private appInfoSvc: AppInfoService) { }

  get appName() {
    return this.appInfoSvc.appName;
  }

  ngOnInit() {
    console.log('Newsfeed');
    this.getNews();
  }

  async getNews() {
    const news = await this.wordpressSvc.getNews();
    news.rows.forEach(post => {
      this.posts.push(post.doc as WordpressPostModel);
    });

  }

  openUrl(url: string) {
    this.appInfoSvc.openUrl(url);
  }

  onOpen(post: WordpressPostModel) {
    this.currentPost = post;
    this.modal.present();
  }

  onCancel() {
    this.modal.dismiss(null, 'cancel');
  }

  onConfirm() {
    this.modal.dismiss('confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
  }

}
