import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  UserCredential, sendEmailVerification, User, Unsubscribe,
  sendPasswordResetEmail,
  getAuth,
  deleteUser,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  AuthCredential,
  EmailAuthProvider,
  EmailAuthCredential,
  SignInMethod
} from '@angular/fire/auth';
import { Firestore, onSnapshot, doc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginData } from '../models/login-data.model';
import { UserSubscriptionModel } from '../models/user.subscription.model';
import { UserSubscriptionService } from './user-subscription.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange: Unsubscribe;
  subscribeChange: Unsubscribe;
  userSubscription: UserSubscriptionModel;

  constructor(private auth: Auth, private toastController: ToastController, private fs: Firestore,
    private router: Router, private userSubscriptionSvc: UserSubscriptionService) {
    this.authChange = auth.onAuthStateChanged(change => this.onAuthChange(change));
  }

  onAuthChange(user: User) {
    console.log('Auth Change', user);
    // if (user != null) {
    //   this.subscribeChange = onSnapshot(doc(this.fs, 'userSubscriptions', user.uid), (sub) => {
    //     this.userSubscription = sub.data() as UserSubscriptionModel;
    //     this.onUserSubscriptionChange();
    //     console.log('subscription.user.change', this.userSubscription);
    //     console.log('subscription.user.change.active', this.userSubscription.active);
    //   });
    //   // this.subscribeChange = this.userSubscriptionSvc.getUserSubscription(user.uid);
    //   console.log('this.subscribeChange', this.subscribeChange);
    //   console.log('this.userSubscriptionSvc', this.userSubscriptionSvc.getSubscribedUser());
    // }
  }

  onUserSubscriptionChange() {
    // switch (this.userSubscription.active) {
    //   case true:

    //     break;

    //   default:
    //     break;
    // }
  }

  async login({ email, password }: LoginData) {
    try {
      const auth = await signInWithEmailAndPassword(this.auth, email, password);
      console.log('loginWithGoogle->', this.auth.currentUser);
      this.openToast('Login Successful');
      const token = await auth.user.getIdTokenResult(true);
      console.log('token.claims->', token.claims);
      await this.router.navigate(['/pages', 'dashboard']);
      return auth;
    } catch (error) {
      this.openToast('Login Error: ' + error.message);
      console.log('Service.Login.error', error);
    }
  }

  async register(login: LoginData) {
    return createUserWithEmailAndPassword(this.auth, login.email, login.password);
  }

  async deleteUser(password: string) {
    try {
      this.openToast(`Not Implemented`);

    } catch (error) {
      this.openToast(`Error ${error}`);
    }
  }

  async sendVerificationMail() {
    this.openToast('Sending Verification Email...');
    sendEmailVerification(this.auth.currentUser)
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  sendPasswordResetEmail(email: string) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(async () => {
        this.openToast(`Reset Link has been sent. Check ${email}`);
        await this.router.navigate(['/login']);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this.openToast(`Error: ${errorMessage}`);
        // ..
      });
  }

  logout() {
    const auth = signOut(this.auth);
    localStorage.setItem(`${environment.app}.subscription`, 'false');
    this.openToast('You have been logged out');
    this.router.navigate(['/login']);
  }

  currentUser() {
    return this.auth.currentUser;
  }

  private openToast(message: string) {
    this.toastController.create({
      color: 'dark',
      duration: 2000,
      position: 'middle',
      message
    }).then((toastData) => {
      toastData.present();
    });
  }
}
