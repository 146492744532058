/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { AudioModel } from '../../models/audio.model';
import { ContentModel } from '../../models/content.model';
import { PracticeAudioModel } from '../../models/practice-audio.model';
import { Location } from '@angular/common';
declare let $: any;
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PracticeAudioService } from '../../services/practice-audio.service';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
})
export class AudioRecorderComponent implements OnInit {
  @Input() content: ContentModel;
  practices: PracticeAudioModel[];
  audio: AudioModel[];
  ready = false;
  recordingDevice: InputDeviceInfo;

  //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url;
  error;
  file: File;

  // Stopwatch
  counter = 0;
  timerRef;
  running = false;

  // Error Message
  errorMessage = '';

  constructor(private domSanitizer: DomSanitizer, private toastController: ToastController,
    private alertController: AlertController, private practiceSvc: PracticeAudioService,
    private location: Location) { }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  test() {
    console.log('Devices:', this.recordingDevice);
  }
  /**
   * Start recording.
   */
  initiateRecording() {
    this.clearTimer();
    this.url = '';
    this.recording = true;
    const mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    this.startTimer();
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream) {
    const options = {
      mimeType: 'audio/mp3',
      // numberOfAudioChannels: 1,
      // sampleRate: 16000,
    };
    //Start Actual Recording
    const stereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new stereoAudioRecorder(stream, options);
    this.record.record();
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
    this.startTimer();
  }
  /**
   * processRecording Do what ever you want with blob
   *
   * @param blob Blog
   */
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    console.log('blob', blob);
    // console.log('url', this.url);
    this.file = new File([blob], this.getFileName('mp3'), {
      type: 'audio/mp3'
    });
    console.log('url.file', this.file);
    // invokeSaveAsDialog(file);
  }
  /**
   * Process Error.
   */

  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

  getFileName(fileExtension) {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const contentName = this.content.name.replace(' ', '.').toLowerCase();
    console.log('getFileName->', contentName);
    return `${environment.app}-${contentName}-${year}${month}${date}-${this.getRandomString()}.${fileExtension}`;
    // return 'elknut-' + contentName + '-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;
  }

  defaultName() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const time = d.getTime();
    const contentName = this.content.name.replace(' ', '.').toLowerCase();
    return `${contentName}-${year}.${month}.${date}-${time}`;
  }

  getRandomString() {
    if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
      const a = window.crypto.getRandomValues(new Uint32Array(3));
      let token = '';
      for (let i = 0, l = a.length; i < l; i++) {
        token += a[i].toString(36);
      }
      return token;
    } else {
      return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
  }

  startTimer() {
    this.running = !this.running;
    if (this.running) {
      // this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = +((Date.now() - startTime) / 1000).toFixed(2);
      });
    } else {
      // this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  clearTimer() {
    this.running = false;
    // this.startText = 'Start';
    this.counter = 0;
    clearInterval(this.timerRef);
  }

  ngOnInit() {
    this.getDevices();
  }

  async onClickSave() {
    const alert = await this.alertController.create({
      header: 'Save Recording',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { console.log('Canceled'); }
        },
        {
          text: 'OK',
          role: 'save',
          handler: (input) => { this.save(input[0]); }
        }
      ],
      inputs: [
        {
          placeholder: 'Name',
          value: this.defaultName()
        }
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  save(fileName: string) {
    const practice: PracticeAudioModel = {
      name: fileName,
      app: environment.app,
      updated_at: Date.now().toString(),
      content: this.content.id,
      status: 'published'
    };
    console.log('Saving.File.Web', fileName, practice, this.file);
    this.practiceSvc.addPractice(practice, this.file);
    this.location.back();
  }

  async getDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log('Devices.1', devices);
      this.recordingDevice = devices.filter((d) => d.kind === 'audioinput')[0];
      if (this.recordingDevice === undefined) {
        // this.openToast('No Microphone Found. Please Fix');
        this.errorMessage = ' No Microphone found. Please Fix';
      }
      console.log('Devices.2', devices);
    });

    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      /* use the stream */
    } catch (err) {
      this.openToast(`Error Requesting Microphone permissions:${err}`);
      /* handle the error */
    }
  }

  getImage(id: string) {
    return '/assets/img/' + id + '/Audio@1x.png';
  }

  private openToast(message: string) {
    this.toastController.create({
      color: 'dark',
      duration: 5000,
      position: 'top',
      message
    }).then((toastData) => {
      toastData.present();
    });
  }
}
