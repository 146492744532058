/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { NgxStripeModule } from 'ngx-stripe';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp, getApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAuth, getAuth, initializeAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideNgxWebstorage } from 'ngx-webstorage';
import { SharedModule } from './core/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { VimeoAuthInterceptor } from './core/interceptors/vimeo.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { indexedDBLocalPersistence } from 'firebase/auth';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // NgxWebstorageModule.forRoot({ prefix: environment.app, separator: '.', caseSensitive: true }),
    IonicModule.forRoot(),
    AppRoutingModule, provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    provideFunctions(() => {
      const functions = getFunctions(getApp());
      // if (!environment.production) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
      // }
      return functions;
    }),
    SharedModule,
    NgxStripeModule.forRoot(environment.stripePublishKey),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IonicStorageModule.forRoot({
      name: '__elknut'
    })
  ],
  providers: [
    provideNgxWebstorage(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService, UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VimeoAuthInterceptor,
      multi: true,
    },
    HTTP,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
