/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { LocalStorageService } from 'ngx-webstorage';
import {
  CollectionReference, DocumentReference, query, where, getDocs,
  getFirestore, collection, doc, DocumentData, collectionData, Firestore, QueryConstraint
} from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { Content, ContentService } from './content.service';
import { DocStatus } from '../models/doc.status.model';
import { VideoFile, VideoModel, VideoFileSize } from '../models/video.model';
import { ContentModel } from '../models/content.model';

// Database
// import * as PouchDB from 'pouchdb';
import PouchDB from 'node_modules/pouchdb';
import { TipsModel } from '../models/tips.model';
import { AudioModel } from '../models/audio.model';
import { VideoService } from './video.service';
import { VimeoVideoRoot } from '../models/vimeo.model';
import { DownloadService } from './download.service';
import { Entry, File, FileEntry, RemoveResult } from '@awesome-cordova-plugins/file/ngx';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
// addPouchPlugin(require('pouchdb-adapter-idb'));

@Injectable({
  providedIn: 'root'
})
export class OfflineManagementService {
  types = ['main', 'howto', 'sequence', 'tactic', 'subtactic'];
  private contentStringKey = 'content';
  private videoStringKey = 'video';
  private vimeoStringKey = 'vimeo';
  private tipsStringKey = 'tips';
  private audioStringKey = 'audio';
  private favoritesStringKey = 'favorites';

  private contentDb: PouchDB;
  private videoDb: PouchDB;
  private vimeoDb: PouchDB;
  private tipsDb: PouchDB;
  private audioDb: PouchDB;
  // private favorites: PouchDB;
  private thumbnailSize = 3;

  private _storage: Storage | null = null;
  // private favoriteRxDb;


  constructor(private fs: Firestore, private localStorageSvc: LocalStorageService, private videoSvc: VideoService,
    private downloadSvc: DownloadService, private contentSvc: ContentService, private toastController: ToastController,
    private file: File, private storage: Storage) {
    this.contentDb = new PouchDB(this.contentStringKey);
    this.videoDb = new PouchDB(this.videoStringKey);
    this.vimeoDb = new PouchDB(this.vimeoStringKey);
    this.tipsDb = new PouchDB(this.tipsStringKey);
    this.audioDb = new PouchDB(this.audioStringKey);
    // this.favorites = new PouchDB(this.favoritesStringKey);
  }
  async initStorage() {
    console.log('init storage');
    const storage = await this.storage.create();
    console.log('storage.storage', storage);
    this._storage = storage;
  }

  async init() {
    let isSynced = false;
    let contentDbInfo = await this.contentDb.info();
    let videoDbInfo = await this.videoDb.info();
    let tipsDbInfo = await this.tipsDb.info();
    let audioDbInfo = await this.audioDb.info();
    // await this.createRxDbs();
    // console.log('db.info');
    if (
      contentDbInfo.doc_count === 0 ||
      videoDbInfo.doc_count === 0 ||
      tipsDbInfo.doc_count === 0 ||
      audioDbInfo.doc_count === 0) {
      try {
        this.refresh();
        isSynced = true;
      } catch (error) {
        isSynced = false;
      }
    } else {
      isSynced = true;
    }
    return isSynced;
  }

  set(key: string, value: any) {
    console.log('set->', key, value);
    this.localStorageSvc.store(key, value);
    // this._storage?.set(key, value);
  }

  // Destroy All Content
  async destroyDbs() {
    await this.contentDb.destroy();
    await this.tipsDb.destroy();
    await this.audioDb.destroy();
    await this.videoDb.destroy();
    await this.vimeoDb.destroy();
  }

  // Refresh All Content
  async refresh() {
    await this.refreshContent();
    await this.refreshVideos();
    await this.refreshTips();
    await this.refreshAudio();
    await this.refreshVimeo();
  }

  async postRefreshAddThumbnails() {
    // Update Thumbnails
    console.log('Adding Vimeo Thumbnails');
    const vimeos = await this.getAllVimeos();
    const videos = await this.getAllVideos();
    console.log('vimeos.', vimeos);
    console.log('videos.', videos);
    videos.forEach(video => {
      vimeos.forEach(vimeo => {
        if (video.vimeoId === vimeo._id) {
          const thumb = vimeo.pictures.sizes[1].link_with_play_button;
          video.thumbnail = thumb;
          console.log('postRefreshUpdates.video', video);
          this.putVideo(video).then(() => {
            console.log('putVideo', video);
          });
        }
      });
    });
  }

  async refreshContent() {
    console.log('Storing Content');
    await this.contentDb.destroy();
    this.contentDb = new PouchDB(this.contentStringKey);
    const q = query(collection(this.fs, this.contentStringKey),
      where('app', '==', environment.app),
      // where('status', '==', DocStatus.published)
    );
    const querySnapshot = await getDocs(q);
    let contents: ContentModel[] = [] as ContentModel[];
    querySnapshot.forEach((d) => {
      let content: ContentModel = d.data() as ContentModel;
      content.id = d.id;
      content._id = d.id;
      contents.push(content);
      // console.log('refreshContent.ContentModel->', content);
    });
    console.log('refreshContent.contents->', contents);
    this.contentDb.bulkDocs(contents);
    // console.log('RefreshContent->', await this.getAllContent());
  }

  async refreshTips() {
    console.log('Storing Tips');
    await this.tipsDb.destroy();
    this.tipsDb = new PouchDB(this.tipsStringKey);
    const q = query(collection(this.fs, this.tipsStringKey),
      where('app', '==', environment.app),
      where('status', '==', DocStatus.published)
    );
    const querySnapshot = await getDocs(q);
    let tips: TipsModel[] = [] as TipsModel[];
    querySnapshot.forEach((d) => {
      let tip: TipsModel = d.data() as TipsModel;
      tip.id = d.id;
      tip._id = d.id;
      tips.push(tip);
    });
    this.tipsDb.bulkDocs(tips);
  }

  async refreshAudio() {
    console.log('Storing Audio');
    await this.audioDb.destroy();
    this.audioDb = new PouchDB(this.audioStringKey);
    const q = query(collection(this.fs, this.audioStringKey),
      where('app', '==', environment.app),
      where('status', '==', DocStatus.published)
    );
    const querySnapshot = await getDocs(q);
    let audio: AudioModel[] = [] as AudioModel[];
    querySnapshot.forEach((d) => {
      let a: AudioModel = d.data() as AudioModel;
      a.id = d.id;
      a._id = d.id;
      audio.push(a);
    });
    this.audioDb.bulkDocs(audio);
  }

  async getAllContent() {
    const docs = await this.contentDb.allDocs({ include_docs: true });
    let contents: ContentModel[] = [] as ContentModel[];
    docs.rows.forEach((val) => {
      contents.push(val.doc);
    });
    return contents;
  }

  async getContentByType(type: string) {
    let contents = await this.getAllContent();
    const result = contents.filter((content) => content.type === type).sort((a, b) => a.name > b.name ? 1 : -1);
    return result;
  }

  async getSubTactics(parentId: string) {
    let contents = await this.getAllContent();
    const result = contents.filter((content) => content.parentId === parentId);
    return result;
  }

  getContent(id: string): ContentModel {
    return this.contentDb.get(id, (content) => content);
  }

  async refreshVideos() {
    console.log('Storing Video');
    // Refresh the DB
    await this.videoDb.destroy();
    this.videoDb = new PouchDB(this.videoStringKey);
    const q = query(collection(this.fs, this.videoStringKey),
      where('app', '==', environment.app),
      where('status', '==', DocStatus.published)
    );
    const querySnapshot = await getDocs(q);

    let videos: VideoModel[] = [] as VideoModel[];
    querySnapshot.forEach((d) => {
      let video: VideoModel = d.data() as VideoModel;
      video.id = d.id;
      video._id = d.id;
      videos.push(video);
    });
    this.videoDb.bulkDocs(videos);
  }

  async refreshVimeo() {
    console.log('Storing Vimeo');
    // Refresh the DB
    await this.vimeoDb.destroy();
    this.vimeoDb = new PouchDB(this.vimeoStringKey);
    this.getAllVideos().then((videos) => {
      // console.log('Get all Videos', videos);
      videos.forEach(video => {
        if (video.vimeoId !== undefined) {
          console.log('refreshVimeo.vimeoId', video.vimeoId);
          this.videoSvc.getVimeoVid(video.vimeoId).then(vimeo => {
            vimeo._id = video.vimeoId;
            this.vimeoDb.post(vimeo).then(() => {
              console.log('vimeo.updated', vimeo);
            });
            video.thumbnail = vimeo.pictures.sizes[this.thumbnailSize].link_with_play_button;
            console.log('refreshVimeo.updated video', video);
            this.videoDb.put(video).then(() => {
              console.log('Video Thumbnail Added', vimeo._id);
            });
          });
        }
      });
    });
  }

  async getAllVimeos() {
    const docs = await this.vimeoDb.allDocs({ include_docs: true });
    let vimeos: any[] = [] as any[];
    docs.rows.forEach((val) => {
      vimeos.push(val.doc);
    });
    return vimeos as VimeoVideoRoot[];
  }

  async getAllVideos() {
    const docs = await this.videoDb.allDocs({ include_docs: true });
    let videos: VideoModel[] = [] as VideoModel[];
    docs.rows.forEach((val) => {
      videos.push(val.doc);
    });
    return videos;
  }

  async getAllAudio() {
    const docs = await this.audioDb.allDocs({ include_docs: true });
    let audio: AudioModel[] = [] as AudioModel[];
    docs.rows.forEach((val) => {
      audio.push(val.doc);
    });
    // console.log('getAllAudio->', audio);
    return audio;
  }

  async getContentAudio(contentId: string) {
    console.log('getting.getContentAudio', contentId);
    let contentAudio = [] as AudioModel[];
    let audio = await this.getAllAudio();
    // console.log('getContentAudio.all', audio);
    contentAudio = audio.filter((t) => t.contentId === contentId);
    // console.log('getContentAudio', contentId, contentAudio);
    return contentAudio;
  }

  getVideo(id: string): Promise<VideoModel> {
    return this.videoDb.get(id, (video) => video);
  }

  async putVideo(video: VideoModel) {
    await this.videoDb.put(video);
  }

  async getAllTips() {
    const docs = await this.tipsDb.allDocs({ include_docs: true });
    let tips: TipsModel[] = [] as TipsModel[];
    docs.rows.forEach((val) => {
      tips.push(val.doc);
    });
    return tips;
  }

  async getContentTips(contentId: string) {
    console.log('getting.getContentTips', contentId);
    let contentTips = [] as TipsModel[];
    let tips = await this.getAllTips();
    // console.log('getContentTips.all', tips);
    contentTips = tips.filter((t) => t.contentId === contentId);
    // console.log('getContentTips', contentId, contentTips);
    return contentTips;
  }

  async getContentAndVideos() {
    let contents = await this.getAllContent();
    let videos = await this.getAllVideos();
    // let contentVideos: VideoModel[] = [] as VideoModel[];
    contents.forEach((content, index) => {
      content.videos = videos.filter((video) => video.contentId === content.id);
    });
    // console.log('getContentAndVideos.contents->', contents);
  }

  async getContentAndSubItemsByType(type?: string) {
    let contents = await this.getAllContent();
    let videos = await this.getAllVideos();
    let audio = await this.getAllAudio();
    contents.forEach((content, index) => {
      content.videos = videos.filter((video) => video.contentId === content.id);
    });
    contents.forEach((content, index) => {
      content.audio = audio.filter((a) => a.contentId === content.id);
    });
    // console.log('getContentAndVideos.contents->', contents);
    if (type) {
      return contents.filter((content) => content.type === type);
    } else {
      return contents;
    }
    // return contents.filter((content) => content.type === type);
  }

  async getContentVideosByContentId(id: string) {
    let contentVideos: VideoModel[] = [] as VideoModel[];
    let videos = await this.getAllVideos();
    contentVideos = videos.filter((v) => v.contentId === id);
    return contentVideos;
  }

  async setOfflineContentVideoThumbnails() {
    const videos = await this.getAllVideos();
    videos.forEach(async video => {
      console.log('getting vimeo video->', video.name, video.vimeoId);
      if (video.vimeoId !== undefined) {
        const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
      }
      // const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
      // console.log('videos.vimeo->', vimeo);
    });
    // console.log('videos->', videos);
  }

  async setVideoDownload(id: string, vimeoId: string) {
    const vimeo = await this.videoSvc.getVimeoVid(vimeoId);
    const src = this.videoSvc.getHdVideoUrl(vimeo.files);
    const filename = this.contentSvc.getVideoFileName(id);
    console.log('setVideoDownload.id', id);
    console.log('setVideoDownload.vimeo', vimeo);
    console.log('setVideoDownload.src', src);
    console.log('setVideoDownload.filename', filename);
    const file = await this.downloadSvc.downloadVideoFile(filename, src.link);
    console.log('setVideoDownload.file', file);
    if (file) {
      this.localStorageSvc.store(`${this.videoStringKey}-${id}`, true);
      this.openBottomToast(`${filename} downloaded`);
      return true;
    } else {
      return false;
    }
  }

  async setAudioDownload(audio: AudioModel) {
    const filename = this.contentSvc.getAudioFileName(audio.id, audio.audioFile);
    console.log('setAudioDownload.audio', audio);
    console.log('setAudioDownload.newfilename', filename);
    const file = await this.downloadSvc.downloadAudioFile(filename, audio.audioFile);
    if (file) {
      this.localStorageSvc.store(`${this.audioStringKey}-${audio.id}`, true);
      this.openBottomToast(`${filename} downloaded`);
      return true;
    } else {
      return false;
    }
  }

  async removeVideoDownload(id: string) {
    const filename = this.contentSvc.getVideoFileName(id);
    const file: RemoveResult = await this.downloadSvc.removeDownloadVideoFile(filename);
    if (file.success) {
      this.localStorageSvc.clear(`${this.videoStringKey}-${id}`);
      this.openBottomToast(`${filename} removed`);
    }
  }

  async removeAudioDownload(id: string) {
    const filename = this.contentSvc.getAudioFileName(id);
    const file: RemoveResult = await this.downloadSvc.removeDownloadAudioFile(filename);
    if (file.success) {
      this.localStorageSvc.clear(`${this.audioStringKey}-${id}`);
      this.openBottomToast(`${filename} removed`);
    }
  }

  isVideoDownloaded(id: string): boolean {
    const downloaded = this.localStorageSvc.retrieve(`${this.videoStringKey}-${id}`);
    if (downloaded === (true || 'true') && (downloaded !== null)) {
      return true;
    } else {
      return false;
    }
  }

  isAudioDownloaded(id: string): boolean {
    const downloaded = this.localStorageSvc.retrieve(`${this.audioStringKey}-${id}`);
    if (downloaded === (true || 'true') && (downloaded !== null)) {
      return true;
    } else {
      return false;
    }
  }

  isVideoDownloadedObs(id: string) {
    return this.localStorageSvc.observe(`${this.videoStringKey}-${id}`);
  }

  isAudioDownloadedObs(id: string) {
    return this.localStorageSvc.observe(`${this.audioStringKey}-${id}`);
  }

  async getAudioFileBase64(fileName: string) {
    const file = await this.getAudioFile(fileName);
    const f = await Filesystem.readFile({
      path: file.nativeURL,
      // directory: Directory.Data
    });
    const dataUri = `data:audio/mp3;base64,${f.data}`;
    console.log('getAudioFileBase64', dataUri);
    return dataUri;
  }

  async getAudioFile(fileName: string) {
    const path = `${this.file.dataDirectory}${this.audioStringKey}`;
    const folder = await this.file.resolveDirectoryUrl(path);
    return await this.file.getFile(folder, fileName, {});
  }

  async downloadedAudioFile(fileName: string) {
    const files = await this.listAudioFiles();
    // const filtered = files.filter((file) => file.name === fileName);
    try {
      const filtered = files.filter((file) => file.name === fileName);
      return filtered[0];
    } catch (error) {
      console.log('error', error);
      return {} as Entry;
    }
    // return filtered[0];
  }

  async listAudioFiles() {
    let files: Entry[] = [] as Entry[];
    try {
      files = await this.file.listDir(this.file.dataDirectory, this.audioStringKey);
      console.log('listAudioFiles.files', files);
    } catch (error) {
      console.log('listAudioFiles.error', error);
    }
    // const files = await this.file.listDir(this.file.dataDirectory, this.videoFolderKey);
    return files;
  }

  private openBottomToast(message: string) {
    this.toastController.create({
      color: 'dark',
      duration: 1000,
      position: 'bottom',
      message
    }).then((toastData) => {
      toastData.present();
    });
  }

}
