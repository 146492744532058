<ion-card class="media-card">
  <ion-card-header>
    <ion-card-title>
      <img src="/assets/icon/elknut.png" />
      {{offerings?.current.annual.product.title}}
    </ion-card-title>
    <ion-card-subtitle>{{offerings?.current.annual.product.description}}</ion-card-subtitle>
    <ion-card-content>
      <div>{{offerings?.current.annual.product.priceString}}</div>
    </ion-card-content>
  </ion-card-header>
  <ion-footer>
    <ion-row>
      <ion-col center text-center *ngIf="offerings">
        <ion-button (click)="purchase()" color="light" expand="block">
          Subscribe
        </ion-button>
        <!-- <ion-button *ngIf="isSubscribed(product.id)" color="success" expand="block">
          Subscribed
        </ion-button> -->
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-card>
