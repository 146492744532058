import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { httpsCallable, getFunctions, connectFunctionsEmulator, HttpsCallableOptions, httpsCallableFromURL } from '@angular/fire/functions';
import { AuthService } from 'src/app/core/services/auth.service';
import { StripeCustomer, CustomerAddress, CustomerObjectEnum } from 'src/app/core/models/stripe.customer.model';
import { collection, doc, Firestore, getDoc, getDocs, query, setDoc } from '@angular/fire/firestore';
import { StripeSubscriptionModel, StripeSubscriptionRootModel } from '../models/stripe.subscription.model';
import { StripeFirestoreCustomerRecordModel } from '../models/stripe.firestore.customer.record.model';
import { AppInfoService } from './app-info.service';
import { isPlatform } from '@ionic/angular';

import { CapacitorHttp, HttpOptions } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class StripeCustomerService {
  // functions = getFunctions(getApp());
  constructor(private http: HttpClient, private auth: AuthService, private fs: Firestore,
    private appInfoSvc: AppInfoService) {
    // if (environment.useEmulators) {
    //   connectFunctionsEmulator(this.functions, 'localhost', 5001);
    // }
  }

  async createCustomer(customer: StripeCustomer) {
    /** Fire Function */
    // const callable = httpsCallable(this.functions,'myUppercaseFunction');
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/customer-create`);
    const fbt = await this.currentUserToken();
    const body: any = {
      token: environment.stripePublishKey,
      fbt,
      customer
    };
    const options: HttpOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: JSON.stringify(body),
      url: url,
    };
    return CapacitorHttp.post(options);
    // return this.http.post<StripeCustomer>(url, JSON.stringify(body)).toPromise();
  }

  async retrieveCustomer(uid: string): Promise<StripeCustomer> {
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/customer-retrieve`);
    const fbt = await this.currentUserToken();
    const body: any = {
      token: environment.stripePublishKey,
      fbt,
      id: uid
    };
    return lastValueFrom(this.http.post<StripeCustomer>(url, JSON.stringify(body)));
  }
  async listCustomerSubscriptionStripeCap(customer: string) {

    /** API */
    const url = this.appInfoSvc.apiUrl(`api/list-customer-subscription`);
    const body: any = {
      token: environment.stripePublishKey,
      customer
    };
    const options: HttpOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: JSON.stringify(body),
      url: url,
    };
    return CapacitorHttp.post(options);
  }

  async listCustomerSubscriptionStripe(customer: string) {
    // /** Fire Function */
    // const callable = httpsCallable(this.functions, 'listCustomerSubscription');
    // const body: any = {
    //   token: environment.stripePublishKey,
    //   customer
    // };
    // return callable(body);
    // customer = stripeId

    /** API */
    const url = this.appInfoSvc.apiUrl(`api/list-customer-subscription`);
    const body: any = {
      token: environment.stripePublishKey,
      customer
    };
    console.log('listCustomerSubscriptionStripe.url', url, body);
    if (isPlatform('capacitor')) {
      console.log('listCustomerSubscriptionStripe.capacitor');
      // const data = await this.chttp.post(url, body, {});
      const options: HttpOptions = {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        data: JSON.stringify(body),
        url: url,
      };
      return JSON.parse((await CapacitorHttp.post(options)).data);

    } else {
      return lastValueFrom(this.http.post<StripeSubscriptionModel[]>(url, JSON.stringify(body)));
    }
    // return this.chttp.post(url, body, {});
    // return httpsCallableFromURL(this.functions, url, body);
  }

  isSubscribed(productId: string, stripeSubscriptionsModel: StripeSubscriptionModel[]) {
    if ((productId !== undefined) && stripeSubscriptionsModel !== undefined && stripeSubscriptionsModel.length > 0) {
      const result = stripeSubscriptionsModel.filter((subscription) => subscription.plan.product === productId);
      // console.log('result->', result[0].plan.active);
      return result[0].plan.active;
    } else {
      return false;
    }
  }

  async retrieveServerFbCustomer() {
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/customer-fb-retrieve`);
    const fbt = await this.auth.currentUser().getIdToken();;
    const body: any = {
      token: environment.stripePublishKey,
      fbt
    };
    return lastValueFrom(this.http.post<any>(url, JSON.stringify(body)));
  }

  async retrieveFbCustomer(): Promise<any> {
    const uid = await this.auth.currentUser().uid;

    const q = doc(this.fs, 'customers', uid, 'subscriptions', 'sub_1LOwTfD2A6yqfXJ2pG3p0xjk');
    const querySnapshot = await getDoc(q);
    console.log('retrieveFbCustomer.querySnapshot', querySnapshot.data());
    return querySnapshot.data();

  }

  async getStripeCustomerId(): Promise<any> {
    const uid = await this.auth.currentUser().uid;
    const d = doc(this.fs, 'customers', uid);
    const querySnapshot = await getDoc(d);
    console.log('retrieveFbCustomer.querySnapshot', querySnapshot);
    const data = querySnapshot.data();
    if (data) {
      return data.stripeId;
    } else {
      return undefined;
    }
  }

  async createStripeFirestoreRecord(customer: StripeFirestoreCustomerRecordModel) {
    const uid = await this.auth.currentUser().uid;
    await setDoc(doc(this.fs, 'customers', uid), customer);
  }

  async getStripeSubscriptions(): Promise<any> {
    const uid = await this.auth.currentUser().uid;
    const d = collection(this.fs, 'customers', uid);
    const querySnapshot = await getDocs(d);
  }


  private async currentUserToken() {
    const token = await this.auth.currentUser().getIdToken();
    return token;
  }
}
