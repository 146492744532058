import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FavoriteModel } from '../../models/favorite.model';
import { FavoritesService } from '../../services/favorites.service';

import { Auth } from '@angular/fire/auth';
import { onSnapshot, Firestore } from '@angular/fire/firestore';

import { DataType } from 'src/app/core/models/data.type.model';


@Component({
  selector: 'app-listed-favorites',
  templateUrl: './listed-favorites.component.html',
  styleUrls: ['./listed-favorites.component.scss'],
})
export class ListedFavoritesComponent implements OnInit, OnDestroy {
  // favorites: FavoriteModel[] = [] as FavoriteModel[];
  favorites = this.favoritesSvc.favorites;
  unsubscribe;
  constructor(private favoritesSvc: FavoritesService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  // getData() {
  //   // this.favorites = this.favoritesSvc.favorites;
  //   const q = this.favoritesSvc.getFavoritesObs();
  //   this.unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     this.favorites = [] as FavoriteModel[];
  //     querySnapshot.forEach((doc) => {
  //       // console.log('getData->', doc);
  //       this.favorites.push(doc.data() as FavoriteModel);
  //     });
  //   });
  // }

  async remove(event: Event, favorite: FavoriteModel) {
    console.log('favorites.remove.event', event);
    console.log('favorites.remove', favorite);
    event.stopPropagation();
    try {
      event.stopImmediatePropagation();
      await this.favoritesSvc.removeFavorite(favorite);
      // this.unsubscribe();
      // this.getData();
    } catch (error) {
      console.log('favorites.remove.error', error);
    }
  }

  goType(favorite: FavoriteModel) {
    this.router.navigate([`${favorite.refUrl}/${favorite.refId}`]);
  }

}
