/* eslint-disable eqeqeq */
import { Component, Input, OnInit, OnDestroy, effect } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { ActionSheetController } from '@ionic/angular';
import { FavoritesService } from '../../services/favorites.service';
import { DataType } from '../../models/data.type.model';
import { onSnapshot, Firestore } from '@angular/fire/firestore';
import { FavoriteModel } from '../../models/favorite.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TierType } from '../../models/tier.types.model';
import { ContentService } from '../../services/content.service';
import { UserSubscriptionService } from '../../services/user-subscription.service';
import { Auth, Unsubscribe } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listed-item',
  templateUrl: './listed-item.component.html',
  styleUrls: ['./listed-item.component.scss'],
})
export class ListedItemComponent implements OnInit, OnDestroy {
  @Input() item: ContentModel;
  @Input() showDetail = true;
  isFavorite = false;
  favUnsubscribe: Unsubscribe;
  tier = TierType;
  // unsubscribe;
  constructor(private actionSheetController: ActionSheetController, private route: ActivatedRoute,
    private favoriteSvc: FavoritesService, private router: Router, private contentSvc: ContentService,
    private userSubscriptionSvc: UserSubscriptionService, private auth: Auth) {
  }

  get isSubscribed() {
    return this.userSubscriptionSvc.isActiveSubscription;
  }

  get isItemPro(): boolean {
    return this.contentSvc.isItemPro(this.item);
  }

  get showSubscribe(): boolean {
    if (!this.isSubscribed && this.isItemPro) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    this.checkFavoriteObs();
  }


  ngOnDestroy(): void {
    this.favUnsubscribe();
  }

  getImage(id: string) {
    return '/assets/img/' + id + '/Thumb@1x.png';
  }

  async checkFavoriteObs() {
    const q = this.favoriteSvc.isFavoriteObs(this.item.id, DataType.content);
    this.favUnsubscribe = onSnapshot(q, (querySnapshot) => {
      const favorites = [];
      querySnapshot.forEach((doc) => {
        favorites.push(doc.data().refId);
      });
      if (favorites.length > 0) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
      // console.log('checking Favorites Obs', favorites);
    });
  }


  async removeFavorite() {
    // await this.favoriteSvc.removeFavorite(favorite);
    console.log('remove Favorite->', this.item);
    try {
      await this.favoriteSvc.removeFavoriteByRefId(this.item.id);
      this.isFavorite = false;
    } catch (error) {
      console.log('remove Favorite', error);
    }

    // this.checkFavorite();
  }

  async view() {
    // Fix this before going live - currently showing all free as pro for testing
    console.log('view', this.item.id);
    console.log('isSubscribed.isPro', !this.isSubscribed, !this.isItemPro);
    if (!this.isSubscribed && this.isItemPro) {
      await this.router.navigate(['upgrade-account'], { relativeTo: this.route });
    } else {
      await this.router.navigate([this.item.id], { relativeTo: this.route });
    }
  }

  async presentActionSheet(event: MouseEvent) {
    console.log('presentActionSheet', event);
    event.stopImmediatePropagation();
    const favText = this.isFavorite ? 'Remove Favorite' : 'Favorite';
    const actionSheet = await this.actionSheetController.create({
      header: this.item.name,
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: favText,
          icon: 'heart',
          handler: () => {
            if (!this.isFavorite) {
              console.log('Favorite clicked');
              try {
                const result = this.favoriteSvc.makeFavorite(this.item.id, this.item.name, DataType.content, this.router.url);
                console.log('Favorite result', result);
                this.isFavorite = true;
              } catch (error) {
                console.log('Favorite error', error);
              }

            } else {
              console.log('Remove Favorite clicked');
              this.removeFavorite();
            }
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
    });
    await actionSheet.present();
  }

}
