import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from "./_guard/auth.guard";
import { AuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
// import { LoginPageComponent } from "../app/pages/login/login-page/login-page.component";
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/unauthorized']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['pages/dashboard']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/sign-up-landing.module').then(m => m.SignUpLandingPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'sign-up-email',
    loadChildren: () => import('./pages/signup/sign-up-email/sign-up-email.module').then(m => m.SignUpEmailPageModule)
  },
  {
    path: 'sign-up-landing',
    loadChildren: () => import('./pages/signup/sign-up-landing.module').then(m => m.SignUpLandingPageModule)
  },
  {
    path: 'verify-email-address',
    loadChildren: () => import('./pages/verify-email-address/verify-email-address.module').then(m => m.VerifyEmailAddressPageModule)
  },
  {
    path: 'trainer',
    children: [
      {
        path: ':id',
        children: [
          // {
          //   path: '',
          //   loadChildren: () => import('pages/howto/howto-detail/howto-detail.module').then(m => m.HowtoDetailPageModule)
          // },
          {
            path: 'videos',
            loadChildren: () => import('./pages/shared/video-list/video-list.module').then(m => m.VideoListPageModule)
          },
        ]
      }
    ]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesPageModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
