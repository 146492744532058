/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, signal } from '@angular/core';
import { Auth, Unsubscribe } from '@angular/fire/auth';
import {
  Firestore, doc, setDoc, Timestamp, getDoc, query, addDoc,
  collection, where, getDocs, deleteDoc, onSnapshot
} from '@angular/fire/firestore';
import { ToastController, ToastOptions } from '@ionic/angular';
import { stringify } from 'querystring';
import { environment } from 'src/environments/environment';
import { DataType } from '../models/data.type.model';
import { DocStatus } from '../models/doc.status.model';
import { FavoriteModel } from '../models/favorite.model';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  unsubscribe: Unsubscribe;
  favorites = signal<FavoriteModel[]>([] as FavoriteModel[]);
  constructor(private fs: Firestore, private auth: Auth, private toastController: ToastController) { }

  init() {
    console.log('favorite.svc.init');
    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid),
    );

    // const q = query(collection(db, "cities"), where("state", "==", "CA"));
    this.unsubscribe = onSnapshot(q, (querySnapshot) => {
      this.favorites.set(querySnapshot.docs.map((doc) => {
        // console.log(`favoriteRxDb`, doc.data());
        return { ...doc.data(), id: doc.id } as FavoriteModel;
      }))
    });
    console.log('favorite.svc.loaded', this.favorites());
  }

  async makeFavorite(id: string, name: string, type: DataType, url?: string) {
    const base = url.split('/');
    // TODO: Fix URL entered wrongly
    const data: FavoriteModel = {
      userId: this.auth.currentUser.uid,
      refType: type,
      refId: id,
      refName: name,
      refUrl: `/${base[1]}/${base[2]}`,
      updated_at: Timestamp.fromDate(new Date()),
      app: environment.app
    };
    console.log('makeFavorite', data);
    return await addDoc(collection(this.fs, DataType.favorites), data);
    // await addDoc(doc(this.fs, DataType.favorites), data);
    // this.openToast(`${name} has been made a favorite!`, 'success');
  }

  async isFavoriteAsync(id: string, type: DataType) {
    let favorite = {} as FavoriteModel;
    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid),
      where('refId', '==', id),
      where('refType', '==', type)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((f) => {
      favorite = f.data() as FavoriteModel;
    });

    const l = Object.keys(favorite).length;
    // console.log('favorite', l);
    if (l > 0) {
      return true;
    } else {
      return false;
    }
  }

  isFavoriteObs(id: string, type: DataType) {
    // const q = query(collection(this.fs, DataType.favorites),
    //   where('app', '==', environment.app),
    //   where('userId', '==', this.auth.currentUser.uid),
    //   where('refId', '==', id),
    //   where('refType', '==', type)
    // );
    // return q;


    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid),
      where('refId', '==', id),
      where('refType', '==', type)
    );
    return q;
  }

  async removeFavorite(favorite: FavoriteModel) {
    let fav = {} as FavoriteModel;
    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid),
      where('refId', '==', favorite.refId),
      where('refType', '==', favorite.refType)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((f) => {
      fav = f.data() as FavoriteModel;
      fav.id = f.id;
    });

    // console.log('Deleting Favorite.id', fav.id);
    const d = await getDoc(doc(this.fs, favorite.refType, fav.id));
    // console.log('Deleting Favorite.data', d.data());
    try {
      return await deleteDoc(doc(this.fs, DataType.favorites, fav.id));
      // this.openToast(`${favorite.refName} favorite has been removed!`, 'warning');
      // console.log('Deleted');
    } catch (error) {
      console.log('Not Deleted.error', error);
    }
  }

  async removeFavoriteByRefId(refId: string) {
    const favorite = await this.getFavoriteByRefId(refId);
    return await this.removeFavorite(favorite);
    // console.log('removeFavoriteByRefId', favorite);
  }

  async getFavoriteByRefId(refId: string) {
    // Not
    let favorite = {} as FavoriteModel;
    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid),
      where('refId', '==', refId),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log('favorite.svc', d.data());
      favorite = d.data() as FavoriteModel;
      // console.log(doc.id, " => ", doc.data());
    });
    return favorite;
  }

  async getFavoritesAsync() {
    // eslint-disable-next-line prefer-const
    let favorites = [] as FavoriteModel[];
    const q = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log('favorite.svc', d.data());
      favorites.push(d.data() as FavoriteModel);
      // console.log(doc.id, " => ", doc.data());
    });
    return favorites;
  }

  getFavoritesObs() {
    // eslint-disable-next-line prefer-const
    // let favorites = [] as FavoriteModel[];
    const querySnapshot = query(collection(this.fs, DataType.favorites),
      where('app', '==', environment.app),
      where('userId', '==', this.auth.currentUser.uid)
    );
    return querySnapshot;
    // return onSnapshot(q, (querySnapshot) => {
    //   querySnapshot.forEach((f) => {
    //     favorites.push(f.data() as FavoriteModel);
    //   });
    // });
  }

  openToast(message: string, color = 'dark') {
    this.toastController.create({
      color,
      // eslint-disable-next-line object-shorthand
      position: 'top',
      duration: 1000,
      message
    }).then((toastData) => {
      toastData.present();
    });
  }

  isFavorite(itemId: string) {
    console.log('isFavorite.itemId', itemId);
    console.log('isFavorite.uid', this.auth.currentUser.uid);
    console.log('isFavorite.app', environment.app);
    console.log('isFavorite.favorites', this.favorites());
    if (this.favorites().length === 0) {
      this.init();
    }
    this.favorites().forEach((f) => {
      console.log('foreach.isFavorite.itemId', itemId, f.refId);
      if (f.refId === itemId && this.auth.currentUser.uid === f.userId && f.app === environment.app) {
        return true;
      }
    });
    return false;
  }
}
