import { Pipe, PipeTransform } from '@angular/core';
import { About } from '../models/about';

@Pipe({
  name: 'aboutType'
})
export class AboutTypePipe implements PipeTransform {

  transform(value: About[], args?: string): any {
    // console.log('pipe =>', value, args);
    if (value) {
      return value.filter(about => about.type === args);
    } else {
      return null;
    }
  }

}
