<ion-card class="media-card" [routerLink]="['./', 'audio']" router-direction="forward">
  <!-- <img src="/assets/img/river.jpg" /> -->
  <img *ngIf="content" [src]="getImage(content?.id)">
  <div class="icon-overlay">
    <ion-icon name="headset-outline"></ion-icon>
  </div>
  <ion-item lines="none">
    <h5 slot="start">{{content?.name}}</h5>
    <ion-chip slot="end" color="primary" *ngIf="isItemPro()">
      <ion-label>Pro</ion-label>
    </ion-chip>
    <!-- <ion-icon slot="end" name="heart"></ion-icon> -->
  </ion-item>
  <ion-item lines="none" [routerLink]="['./', 'audio']" router-direction="forward">
    <h2>Compare and record audio</h2>
  </ion-item>
</ion-card>
