<ion-list>
  <ion-item *ngFor="let favorite of favorites()" lines="none" border-radius="5px">
    <!-- <ion-icon slot="start" name="document-text-outline"></ion-icon> -->
    <ion-icon name="heart" slot="start" color="danger"></ion-icon>
    <ion-label class="ion-text-wrap">
      <a color="dark" button fill="clear" [routerLink]="[favorite.refUrl, favorite.refId]" router-direction="forward">
        {{favorite?.refName}}
      </a>
    </ion-label>
    <!-- <ion-note slot="end" color="primary">{{favorite?.refType}}</ion-note> -->
    <ion-button (click)="remove($event, favorite)" fill="clear" slot="end" color="dark">
      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
    <!-- <ion-button [routerLink]="[favorite.refUrl, favorite.refId]" router-direction="forward" fill="clear" slot="end"
      color="dark">
      <ion-icon slot="icon-only" name="link-outline"></ion-icon>
    </ion-button> -->


  </ion-item>
  <ion-item *ngIf="favorites()?.length < 1">
    <ion-label>
      <h2>You have no favorities.</h2>
      Click a <ion-icon name="heart" color="danger"></ion-icon> next to something to add one.
    </ion-label>

  </ion-item>
</ion-list>