/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StripeCustomer, CustomerObjectEnum } from 'src/app/core/models/stripe.customer.model';
import { UserBilling } from 'src/app/core/models/user-address.model';
import { Auth } from '@angular/fire/auth';
import { StripeCustomerService } from 'src/app/core/services/stripe-customer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-billing-information',
  templateUrl: './customer-billing-information.component.html',
  styleUrls: ['./customer-billing-information.component.scss'],
})
export class CustomerBillingInformationComponent implements OnInit {
  form: FormGroup;
  customer: StripeCustomer;
  isNew = false;
  constructor(private auth: Auth, private stripeCustSvc: StripeCustomerService) { }

  ngOnInit() {
    this.createForm();
    this.getCustomerInfo();
  }

  createForm() {
    this.form = new FormGroup({
      line1: new FormControl(''),
      line2: new FormControl('',),
      postal_code: new FormControl('',),
      city: new FormControl('',),
      state: new FormControl('',),
      country: new FormControl('',),
    });
  }

  async getCustomerInfo() {
    const fbuser = await this.auth.currentUser;
    this.customer = await this.stripeCustSvc.retrieveCustomer(fbuser.uid);
    if (this.customer) {
      this.form.patchValue(this.customer.address);
      this.isNew = false;
    } else {
      this.isNew = true;
    }
    console.log('getCustomerInfo', this.customer);
  }

  async onSubmit() {
    if (this.form.valid) {
      const imageName = 'profile-image.png';
      const fbuser = await this.auth.currentUser;
      const customer: StripeCustomer = {
        id: fbuser.uid,
        address: {
          line1: this.form.value.line1,
          line2: this.form.value.line2,
          postal_code: this.form.value.postal_code,
          city: this.form.value.city,
          state: this.form.value.state,
          country: this.form.value.country
        },
        email: fbuser.email,
        name: fbuser.displayName,
        description: `${environment.appName} User`
        // object: CustomerObjectEnum.customer
      };
      console.log('Customer', customer);
      if (this.isNew) {
        console.log('Create New Customer');
        const resp = await this.stripeCustSvc.createCustomer(customer);
      } else {
        console.log('Update Customer');
      }

    }

  }
}
