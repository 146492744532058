/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { AudioModel } from '../../models/audio.model';
import { isPlatform } from '@ionic/angular';
// import { VideoService } from '../../services/video.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() content: ContentModel;
  @Input() audio: AudioModel;
  @ViewChild('media') media: ElementRef;
  playing = false;
  progress: number = 0.00;
  duration: number = 0.00;
  currentTime: number = 0.00;
  audioFile = '';
  readyState = (isPlatform('ios')) ? 1 : 2;
  loop = false;

  private audioControl: HTMLMediaElement;

  constructor() {
    this.resetProgress();
  }

  ngOnInit() {
    console.log('loaded current audio', this.audio);
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes.audio;
    if (!change.firstChange) {
      console.log('audio.change', this.audio.id);
      this.audioControl = this.media.nativeElement;
      this.audioControl.src = this.audio.audioFile;
      this.resetProgress();
      this.buildAudio();
      this.pause();
    }
  }

  resetProgress() {
    this.progress = 0.00;
    this.duration = 0.00;
    this.currentTime = 0.00;
  }

  play(): void {
    if (this.media) {
      console.log('play.readystate', this.audioControl.readyState, this.readyState);
      if (this.audioControl.readyState >= this.readyState) {
        this.audioControl.play();
        this.playing = true;
        this.audioControl.loop = this.loop;
        console.log('currentTime.duration', this.audioControl.duration);
        // console.log('currentTime.timeStamp', ts);
        this.audioControl.addEventListener('timeupdate', (currentTime) => {
          // Code to update progress bar goes here
          this.currentTime = this.audioControl.currentTime;
          const calc = this.audioControl.currentTime / this.audioControl.duration;
          this.progress = calc;
        });
      }
    }
  }

  onClickLoop() {
    this.loop = !this.loop;
    this.audioControl.loop = !this.audioControl.loop;
  }

  pause(): void {
    if (this.media) {
      this.audioControl.pause();
      console.log('this.audioControl.currentTime', this.audioControl.currentTime);
      this.playing = false;
    }
  }

  forward() {
    this.audioControl.currentTime = this.audioControl.currentTime + 1;
  }

  back() {
    this.audioControl.currentTime = this.audioControl.currentTime - 1;
  }

  isPlayDisabled() {
    if (this.duration < 0.1) {
      return true;
    } else {
      return false;
    }
  }

  buildAudio() {
    // Construct the audio settings;
    if (this.audio && this.audioControl) {
      this.audioControl.loop = false;
      this.audioControl.addEventListener('progress', (progress) => {
        // console.log('progress:', progress);
      });
      this.audioControl.addEventListener('ended', (event) => {
        // console.log('ended:', event);
        this.playing = false;
      });
      this.audioControl.addEventListener('playing', (playing) => {
        // console.log('playing', playing);
      }
      );
      this.audioControl.addEventListener('loadedmetadata', (data) => {
        this.duration = this.audioControl.duration;
      }
      );
    }
  }

  public ngAfterViewInit() {
    this.audioControl = this.media.nativeElement;
    this.audioFile = this.audio.audioFile;
    this.buildAudio();
  }

}
