import { Injectable } from '@angular/core';
import { VideoFile, VideoThumbnailSize, VimeoVideo, VideoFileSize } from '../models/video.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Firestore, collection, getDoc, getDocs, query, where, doc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private vimeoUrl = 'https://api.vimeo.com/';
  constructor(private http: HttpClient, private fs: Firestore) { }

  getVimeoVid(id: string) {
    const url = this.vimeoUrl + 'videos/' + id;
    return firstValueFrom(this.http.get<VimeoVideo>(url));
  }

  getVideoThumbnail(images: VideoThumbnailSize[], size: number): VideoThumbnailSize {
    let thumb: VideoThumbnailSize = {} as VideoThumbnailSize;
    images.forEach(thumbnail => {
      // console.log('Thumbnail->', thumbnail.height, size);
      if (thumbnail.height === size) {
        thumb = thumbnail;
      }
    });
    return thumb;
  }

  getAdaptiveVideoUrl(files: VideoFile[]): VideoFile {
    let vFile: VideoFile = {} as VideoFile;
    files.forEach(file => {
      if (file.quality === 'hls') {
        vFile = file;
      }
    });
    return vFile;
  }

  getHdVideoUrl(files: VideoFile[]): VideoFile {
    return files.filter((f) => f.public_name === VideoFileSize.seven)[0];
    // let vFile: VideoFile = {} as VideoFile;
    // files.forEach(file => {
    //   if (file.quality === 'hd' && file.height === 720) {
    //     vFile = file;
    //   }
    // });
    // return vFile;
  }

  calculateVidSize(bytes: number): string {
    const num = (bytes * 0.000001).toFixed(2);
    return num;
  }

  getFileName(videoFile: string) {
    const split = videoFile.split('/');
    return split.slice(-1).pop();
  }

  async getVideo(id: string) {
    const docRef = doc(this.fs, 'video', id);
    return getDoc(docRef);
  }

}
