/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Auth, IdTokenResult } from '@angular/fire/auth';
import { CustomerInfo, Purchases, PurchasesEntitlementInfo, PurchasesOfferings } from '@awesome-cordova-plugins/purchases/ngx';
import { isPlatform, LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StripeCustomer } from '../models/stripe.customer.model';
import { StripeFirestoreCustomerRecordModel } from '../models/stripe.firestore.customer.record.model';
import { StripeSubscriptionModel } from '../models/stripe.subscription.model';
import {
  PaymentProvidersEnum, UserSubscriptionModel, RevenueCatStoreProvidersEnum,
  RevCatUserSubscriptionModel
} from '../models/user.subscription.model';
import { StripeCustomerService } from './stripe-customer.service';
import { StripeMembershipService } from './stripe-membership.service';
import { UserSubscriptionService } from './user-subscription.service';

@Injectable({
  providedIn: 'root'
})
export class GgtMemberShipService {
  stripeId: string;
  stripeSubscriptions: StripeSubscriptionModel[];
  purchaseInfo: CustomerInfo;
  offerings: PurchasesOfferings;
  claims: UserSubscriptionModel[] = [] as UserSubscriptionModel[];

  constructor(private stripeCustomerSvc: StripeCustomerService, private auth: Auth,
    private userSubscriptionSvc: UserSubscriptionService, private purchases: Purchases,
    private stripeMemSvc: StripeMembershipService, private loadingCtrl: LoadingController) { }


  async isSubscribed(productId: string) {
    // let subscribed = false;
    // let provider = PaymentProvidersEnum.stripe;
    const isStripeActive = await this.checkStripe(productId);
    const isStoreActive = await this.checkAppStores();
    // console.log('all.claims', this.claims, environment.app);
    let c = this.claims.filter(claim => claim.active == (true || '1') && claim.app === environment.app)[0];
    const s = this.isValidJson(c) ? c : JSON.parse(c as any);

    // console.log('isSubscribed.c', c);
    // console.log('isSubscribed.s', s);

    return s;
    // return true;
  }

  async checkStripe(productId: string) {
    console.log('GgtMemberShipService.checkStripe.productId', productId);
    const stripeId = await this.getStripeId();
    const subscriptions = await this.stripeCustomerSvc.listCustomerSubscriptionStripe(stripeId);
    if (subscriptions.length > 0) {
      console.log('GgtMemberShipService.checkStripe.subscriptions', subscriptions);
      const stripeSubscription = subscriptions.filter((sub) => sub.plan.product === productId)[0];
      const claim: UserSubscriptionModel = await this.createStripeClaim(stripeSubscription);
      const s = this.isValidJson(claim) ? claim : JSON.parse(claim as any);
      this.claims.push(s);
      console.log('stripe.claim', claim, s);
      // if (this.isJsonString(claim)) {
      //   const s = JSON.parse(claim as any);
      //   this.claims.push(s);
      // } else {
      //   this.claims.push(claim);
      // }

      return s;
    } else {
      const claim: UserSubscriptionModel = undefined;
      return claim;
    }
    // stripeSubscription.plan.active;
  }

  async createStripeClaim(stripeSubscription: StripeSubscriptionModel) {
    const userSubscription: UserSubscriptionModel = {
      updated_at: new Date(),
      created: stripeSubscription.created,
      app: environment.app,
      current_period_end: stripeSubscription.current_period_end,
      current_period_start: stripeSubscription.current_period_start,
      active: stripeSubscription.plan.active,
      provider: PaymentProvidersEnum.stripe,
    };
    return await this.userSubscriptionSvc.createClaimSubscription(userSubscription).then(async () => {
      return await (await this.auth.currentUser.getIdTokenResult(true)).claims.app[environment.app];
      console.log('retrieved claims');
    });
  }

  async checkAppStores() {
    this.purchases.setAttributes({ uid: this.auth.currentUser.uid });
    try {
      const purchaseInfo = await this.purchases.getCustomerInfo();
      console.log('checkAppStores.purchaseInfo', purchaseInfo);
      const info: RevCatUserSubscriptionModel = purchaseInfo.entitlements.all[environment.products.revenueCatPro];
      // const info: RevCatUserSubscriptionModel = purchaseInfo.entitlements.active[environment.products.revenueCatPro];
      // console.log('purchaseInfo.entitlements.all', purchaseInfo.entitlements.all);
      console.log('checkAppStores.info', info);
      // Create the claim True or False
      // const isActive = this.isRevenueCatActive(info);
      const claim: UserSubscriptionModel = await this.createStoreClaim(info);
      const s = this.isValidJson(claim) ? claim : JSON.parse(claim as any);
      this.claims.push(s);
      console.log('checkAppStores.claim', claim, s);
      // if (this.isJsonString(claim)) {
      //   const s = JSON.parse(claim as any);
      //   this.claims.push(s);
      // } else {
      //   this.claims.push(claim);
      // }
      return s;
    } catch (error) {
      console.log('No AppStore', error);
    }
  }

  async createStoreClaim(purchaseInfo: RevCatUserSubscriptionModel) {
    let provider = PaymentProvidersEnum.appstore;
    switch (purchaseInfo.store) {
      case RevenueCatStoreProvidersEnum.playstore:
        provider = PaymentProvidersEnum.playstore;
        break;

      case RevenueCatStoreProvidersEnum.stripe:
        provider = PaymentProvidersEnum.stripe;
        break;
      default:

        break;
    }
    // const provider = purchaseInfo. ? PaymentProvidersEnum.appstore : PaymentProvidersEnum.playstore;
    if (purchaseInfo.isActive || purchaseInfo.isSandbox) {
      const userSubscription: UserSubscriptionModel = {
        updated_at: new Date(),
        created: purchaseInfo.originalPurchaseDateMillis,
        app: environment.app,
        current_period_end: purchaseInfo.expirationDateMillis,
        current_period_start: purchaseInfo.latestPurchaseDateMillis,
        active: (purchaseInfo.isActive || purchaseInfo.isSandbox) ? true : false,
        provider,
      };
      console.log('this.purchaseInfo.userSubscription', userSubscription);
      return await this.userSubscriptionSvc.createClaimSubscription(userSubscription).then(async () => {
        // await this.auth.currentUser.getIdTokenResult(true);
        console.log('createStoreClaim.Updated Subscription');
        const token = await this.auth.currentUser.getIdTokenResult(true);
        const claim = this.isValidJson(token.claims.app[environment.app])
          ? token.claims.app[environment.app] : JSON.parse(token.claims.app[environment.app]);
        return claim;
        // let sub = await (await this.auth.currentUser.getIdTokenResult(true)).claims.app[environment.app];
        // if (this.isJsonString(sub)) {
        //   sub = JSON.parse(sub);
        // }
        // console.log('createStoreClaim.Subscription', sub);
        // return await (await this.auth.currentUser.getIdTokenResult(true)).claims.app[environment.app];
      });
    } else {
      const claim: UserSubscriptionModel = undefined;
      return claim;
    }
  }

  async getCurrentAppClaim() {
    const token = await this.auth.currentUser.getIdTokenResult(true);
    console.log('getCurrentAppClaim.token', token, this.isValidJson(token.claims.app[environment.app]));
    const claim = this.isValidJson(token.claims.app[environment.app])
      ? token.claims.app[environment.app] : JSON.parse(token.claims.app[environment.app]);
    // let claim;
    // if (this.isJsonString(token.claims.app[environment.app])) {
    //   claim = JSON.parse(token.claims.app[environment.app]);
    // } else {
    //   claim = token.claims.app[environment.app];
    // }
    return claim;
  }

  async manageCapPurchase() {
    try {
      this.purchases.setAttributes({ uid: this.auth.currentUser.uid });
      this.purchaseInfo = await this.purchases.getCustomerInfo();
      this.offerings = await this.purchases.getOfferings();
      const purchase = await this.purchases.purchaseProduct(this.offerings.current.annual.product.identifier);
      this.purchaseInfo = purchase.customerInfo;
      this.getPurchaseInfo(purchase.customerInfo);
      console.log('purchased', purchase.customerInfo);
      return true;
    } catch (error) {
      console.log('purchase.error', error);
      return false;
    }
  }











  async loadStripeSubscriptions() {
    console.log('loadStripeSubscriptions');
    this.stripeSubscriptions = await this.stripeCustomerSvc.listCustomerSubscriptionStripe(this.stripeId);
    console.log('loadStripeSubscriptions->', this.stripeSubscriptions);
    // }
    // this.stripeSubscriptions = subModel as StripeSubscriptionModel[];
    this.stripeSubscriptions.forEach(subscription => {
      this.verifyStripeSubscription(subscription);
    });
  }

  async getStripeId() {
    this.stripeId = await this.stripeCustomerSvc.getStripeCustomerId();
    if (!this.stripeId) {
      console.log('No Stripe ID!');
      this.createStripeId();
    }
    return this.stripeId;
    // if (this.stripeId) {
    //   console.log('stripeId', this.stripeId);
    //   this.loadStripeSubscriptions();
    // }
  }

  async createStripeId() {
    console.log('Creating Stripe Customer');
    const cust: StripeCustomer = {
      email: this.auth.currentUser.email,
      name: this.auth.currentUser.displayName,
    };
    const createdCust = await this.stripeCustomerSvc.createCustomer(cust);
    console.log('Created Stripe Customer', JSON.parse(createdCust.data));
    this.createFirebaseCustRecord(JSON.parse(createdCust.data));
    this.stripeId = await this.stripeCustomerSvc.getStripeCustomerId();
  }

  async createFirebaseCustRecord(createdCust: StripeCustomer) {
    // Create Firebase Customer Record
    console.log('Creating FB Customer Record');
    const url = `https://dashboard.stripe.com/customers/${createdCust.id}`;
    const fsCust: StripeFirestoreCustomerRecordModel = {
      email: this.auth.currentUser.email,
      stripeId: createdCust.id,
      stripeLink: url
    };
    await this.stripeCustomerSvc.createStripeFirestoreRecord(fsCust);
    this.stripeId = createdCust.id;
    this.loadStripeSubscriptions();
  }

  async getMemberShipPortal() {
    const url = await this.stripeMemSvc.getCustomerPortalUrl();
    console.log('onClickMemberShipPortal', url);
    // this.portalUrl = url;
    if (url !== '') {
      // this.portalModal.present();
      // this.showLoading('Opening Stripe Secure Payment Portal');
      window.location.assign(url);
    }
  }

  async verifyStripeSubscription(stripeSubscription: StripeSubscriptionModel) {
    const subscription = await this.userSubscriptionSvc.verifyStripeSubscription(this.auth.currentUser.uid);
    console.log('Subscription Verified');
    // if (!subscription && stripeSubscription) {
    //   console.log('Creating Subscription');
    const userSubscription: UserSubscriptionModel = {
      updated_at: new Date(),
      created: stripeSubscription.created,
      app: environment.app,
      current_period_end: stripeSubscription.current_period_end,
      current_period_start: stripeSubscription.current_period_start,
      active: stripeSubscription.plan.active,
      provider: PaymentProvidersEnum.stripe,
    };
    const claim = await this.userSubscriptionSvc.createClaimSubscription(userSubscription).then(async () => {
      // await this.auth.currentUser.getIdToken();
      const claims = await this.auth.currentUser.getIdTokenResult(true);
      console.log('retrieved claims', claims);
    });

    // const doc = await this.userSubscriptionSvc.createStripeSubscription(this.auth.currentUser.uid, userSubscription);
    // }
  }

  async clearClaims() {
    const cleared = await this.userSubscriptionSvc.removeClaimSubscription();
    console.log('Cleared Claim', cleared);
    this.claims = [];
    // const idToken = await this.auth.currentUser.getIdToken(true);
    const token = await this.auth.currentUser.getIdTokenResult(true);
    console.log('Refresh Cleared Claim', token);
  }


  // if (this.isCapacitor()) {
  //   this.getPurchaseInfo();
  // }

  async getPurchaseInfo(purchaseInfo?: CustomerInfo) {
    this.purchases.setAttributes({ uid: this.auth.currentUser.uid });
    if (!purchaseInfo) {
      this.purchaseInfo = await this.purchases.getCustomerInfo();
    }
    const info = this.revenueCatPro();
    console.log('this.purchaseInfo.info', info);
    let isActive = false;
    if (info) {
      isActive = this.isRevenueCatActive(info);
    }
    const i = info as any;
    const provider = isPlatform('ios') ? PaymentProvidersEnum.appstore : PaymentProvidersEnum.playstore;
    if (isActive) {
      const userSubscription: UserSubscriptionModel = {
        updated_at: new Date(),
        created: i.originalPurchaseDateMillis,
        app: environment.app,
        current_period_end: i.expirationDateMillis,
        current_period_start: i.latestPurchaseDateMillis,
        active: isActive,
        provider,
      };
      console.log('this.purchaseInfo.userSubscription', userSubscription);
      await this.userSubscriptionSvc.createClaimSubscription(userSubscription).then(async () => {
        await this.auth.currentUser.getIdTokenResult(true);
        console.log('Updated Subscription');
      });
    }
  }

  async capPurchase() {
    try {
      const offerings = await this.purchases.getOfferings();
      const purchase = await this.purchases.purchaseProduct(offerings.current.annual.product.identifier);
      this.purchaseInfo = purchase.customerInfo;
      this.getPurchaseInfo(purchase.customerInfo);
      console.log('purchased', purchase.customerInfo);
    } catch (error) {
      console.log('purchase.error', error);
    }
  }

  isValidJson(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return true;
    }
    return false;
  }

  private revenueCatPro() {
    return this.purchaseInfo.entitlements.all[environment.products.revenueCatPro];
  }

  private isRevenueCatActive(info: PurchasesEntitlementInfo) {
    if (info.isActive || info.isSandbox) {
      return true;
    } else {
      return false;
    }
  }

  private async showLoading(message: string) {
    const loading = await this.loadingCtrl.create({
      message,
      duration: 5000
    });

    await loading.present();
  }
}
