<ion-card class="media-card" [routerLink]="['./', 'videos']" router-direction="forward">
  <img [src]="thumbnail" appImgFallbackCard2 />
  <div class="icon-overlay">
    <ion-icon name="videocam-outline"></ion-icon>
  </div>
  <ion-item lines="none">
    <h5 slot="start">{{content?.name}}</h5>
    <ion-chip slot="end" color="primary" *ngIf="content.tier === tier.pro">
      <ion-label>Pro</ion-label>
    </ion-chip>
    <!-- <ion-icon slot="end" name="heart"></ion-icon> -->
  </ion-item>
  <ion-item lines="none" [routerLink]="['./', 'videos']" router-direction="forward">
    <h2>Video Instruction</h2>
  </ion-item>
</ion-card>
