import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { VideoModel } from '../../models/video.model';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';

@Component({
  selector: 'app-listed-video-item',
  templateUrl: './listed-video-item.component.html',
  styleUrls: ['./listed-video-item.component.scss'],
})
export class ListedVideoItemComponent implements OnInit {
  @Input() content: ContentModel;
  @Input() video: VideoModel;
  @ViewChild(IonModal) modal: IonModal;

  message = 'This modal example uses triggers to automatically open a modal when the button is clicked.';
  name: string;
  constructor() { }

  ngOnInit() {
    console.log('Video.Content', this.content);
    console.log('Videos', this.video);
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(this.name, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.message = `Hello, ${ev.detail.data}!`;
    }
  }

}
