<section class="ion-text-center ion-padding-top">
  <img *ngIf="content" [src]="getImage(content?.id)">
</section>
<ion-card class="ion-text-center">
  <h2>{{counter | number}}s</h2>
  <!-- <vg-player> -->
  <!-- <audio #media vg-type="audio">
    <source type="audio/mpeg">
  </audio> -->
  <audio controls="" *ngIf="!recording && url">
    <source [src]="sanitize(url)" type="audio/aac">
  </audio>
</ion-card>
<section class="ion-text-center record-action-section">
  <ion-button (click)="startRecording()" *ngIf="!recording" fill="clear" color="success" size="large"
    class="player-control-record">
    <ion-icon slot="icon-only" name="mic-circle-outline"></ion-icon>
  </ion-button>
  <ion-button (click)="stopRecording()" *ngIf="recording" fill="clear" color="danger" size="large"
    class="player-control-record">
    <ion-icon slot="icon-only" name="mic-off-circle-outline"></ion-icon>
  </ion-button>
</section>
<section *ngIf="errorMessage !== ''" class="ion-padding ion-text-center">
  <ion-label color="danger">{{errorMessage}}</ion-label>
</section>
<section class="ion-padding">
  <ion-button [disabled]="!recording && !url" (click)="onClickSave()" fill="outline" expand="block" color="primary">
    SAVE
  </ion-button>
</section>
