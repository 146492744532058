import { Pipe, PipeTransform } from '@angular/core';
import { ContentModel } from '../models/content.model';

@Pipe({
  name: 'contentFilter'
})
export class ContentFilterPipe implements PipeTransform {

  transform(items: ContentModel[], filter: string): ContentModel[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().indexOf(filter) !== -1);
  }

}
