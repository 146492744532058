/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WordpressPostModel } from '../models/wordpress.post.model';
import PouchDB from 'node_modules/pouchdb';
import { throwIfEmpty } from 'rxjs';
import { lastValueFrom } from 'rxjs';
// import { HTTP } from '@awesome-cordova-plugins/http/ngx';
// import { Platform, isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  private newsStringKey = 'news';
  private newsDb: PouchDB;

  constructor(private http: HttpClient) {
    this.newsDb = new PouchDB(this.newsStringKey);
  }
  // private chttp: HTTP
  getPosts() {
    const url = `https://gotgametech.com/wp-json/wp/v2/posts?
      tags=${environment.wordpressTagId}
      &per_page=${environment.wordpressTagLimit}`;
    return lastValueFrom(this.http.get<WordpressPostModel[]>(url));
  }

  async getNews() {
    console.log('Storing News');
    try {
      const posts: WordpressPostModel[] = [] as WordpressPostModel[];
      const ps = await this.getPosts();
      ps.forEach(post => {
        const p: WordpressPostModel = {} as WordpressPostModel;
        p.id = post.id;
        p._id = post.id.toString();
        p.content = post.content;
        p.excerpt = post.excerpt;
        p.date = post.date;
        p.status = post.status;
        p.title = post.title;
        p.link = post.link;
        posts.push(p);
        // console.log('post', p);
      });
      this.newsDb.bulkDocs(posts);
    } catch (error) {
      console.log('Issue with Updating Docs', error);
    }
    return this.newsDb.allDocs({ include_docs: true });
  }

  // getCapPosts() {
  //   const url = `https://gotgametech.com/wp-json/wp/v2/posts?
  //     tags=${environment.wordpressTagId}
  //     &per_page=${environment.wordpressTagLimit}`;
  //   return this.chttp.get(url, {}, {});
  // }

}
