<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="ion-padding-start ion-padding-end">
  <ion-item>
    <ion-label position="stacked">Address 1</ion-label>
    <ion-input formControlName="line1" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">Address 2</ion-label>
    <ion-input formControlName="line2" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">City</ion-label>
    <ion-input formControlName="city" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">Zip</ion-label>
    <ion-input formControlName="postal_code" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">State</ion-label>
    <ion-input formControlName="state" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">Country</ion-label>
    <ion-input formControlName="country" type="text"></ion-input>
  </ion-item>


  <div class="form-footer">
    <p class="ion-padding-start ion-padding-end">
      <ion-button [disabled]="form.invalid" color="primary" expand="block" type="submit">UPDATE BILLING
      </ion-button>
    </p>
  </div>
</form>
