<div class="video-player-wrapper" *ngIf="ready">
  <vg-player *ngIf="show" id="fullscreen" (onPlayerReady)="videoPlayerInit($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video #media [vgMedia]="$any(media)" [src]="currentVideo.src" id="singleVideo" preload="auto" crossorigin>
    </video>
  </vg-player>
  <ion-list>
    <!-- <ion-list-header *ngIf="videoItems.length > 1" color="dark">
      <h2>{{ getName(currentVideo.name)}}</h2>
    </ion-list-header> -->
    <ion-list-header color="dark">
      <h2>Video List</h2>
      <!-- <ion-label>
        Video List
      </ion-label> -->
      <!-- <ion-button *ngIf="isFavorite">
        <ion-icon name="heart" color="danger"></ion-icon>
      </ion-button>
      <ion-button *ngIf="!isFavorite">
        <ion-icon name="heart-outline" color="danger"></ion-icon>
      </ion-button> -->
      <!-- *ngIf="isFavorite" -->
      <!-- <ion-icon name="heart" slot="end" color="danger"></ion-icon> -->
    </ion-list-header>
    <ion-item [attr.color]="i === activeIndex ? 'light' : ''" *ngFor="let vdo of videoItems; let i = index"
      [attr.data-index]="i" lines="none" class="ion-text-wrap">
      <ion-label class="ion-text-wrap">
        <ion-label>
          <h2>{{ getName(vdo.name) }}</h2>
        </ion-label>
        <ion-button slot="start" color="danger" *ngIf="isDownloaded(vdo.name)" size="large" fill="clear"
          (click)="playOffline(vdo.src, i)">
          <ion-icon slot="start" color="primary" name="play-outline"></ion-icon>
          PLAY
        </ion-button>
        <ion-button slot="start" color="danger" *ngIf="!isDownloaded(vdo.name)" size="large" fill="clear"
          (click)="startPlaylistVdo(vdo, i)">
          <ion-icon slot="start" color="primary" name="play-outline"></ion-icon>
          PLAY
        </ion-button>
        <ion-button slot="end" color="danger" *ngIf="isDownloaded(vdo.name)" size="large" fill="clear"
          (click)="removeVideoDownload(vdo, i)">
          <ion-icon slot="start" color="primary" name="cloud-done"></ion-icon>
          REMOVE
        </ion-button>
        <ion-button slot="end" *ngIf="!isDownloaded(vdo.name) && !isTrainer" (click)="download(vdo, i)" size="large"
          fill="clear">
          <ion-icon slot="icon-only" color="primary" name="cloud-download-outline"></ion-icon>
        </ion-button>
      </ion-label>


    </ion-item>
  </ion-list>
</div>
<!-- (click)="startPlaylistVdo(vdo, i)" -->

<!-- <div class="video-player-wrapper" *ngIf="ready">
  <vg-player (onPlayerReady)="videoPlayerInit($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video #media [vgMedia]="$any(media)" [src]="currentVideo.src" id="singleVideo" preload="auto" crossorigin>
    </video>
  </vg-player>
  <ul class="player-list">
    <li *ngFor="let vdo of videoItems; let $index = index" (click)="startPlaylistVdo(vdo, $index)"
      [class.selected]="vdo === currentVideo">
      {{ vdo.name }}
    </li>
  </ul>
</div> -->
<!-- <div class="video-player-wrapper" *ngIf="ready">
  <vg-player (onPlayerReady)="videoPlayerInit($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>
    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video #media [vgMedia]="$any(media)" [src]="getSafe(currentVideo.src)" id="singleVideo" preload="auto" crossorigin>
    </video>
  </vg-player>
  <ion-list class="player-list">
    <ion-item *ngFor="let vdo of videoItems; let i = index" (click)="startPlaylistVdo(vdo, i)" [attr.data-index]="i"
      [class.selected]="vdo === currentVideo">
      <ion-label slot="start">{{ vdo.name }}</ion-label>
      <ion-button *ngIf="!isDownloaded(vdo.name)" slot="end" (click)="download(vdo, i)">Download</ion-button>
      <ion-button *ngIf="isDownloaded(vdo.name)" slot="end">Remove</ion-button> -->
<!-- (click)="removeAlert(video.id)" -->
<!-- <ion-button color="danger" *ngIf="isDownloaded(vdo.name)" fill="clear" size="small" slot="end"
  (click)="removeVideoDownload(vdo, i)">
  <ion-icon slot="start" color="primary" name="cloud-done"></ion-icon>
  REMOVE
</ion-button>
<ion-button *ngIf="!isDownloaded(vdo.name)" (click)="download(vdo, i)" fill="clear" size="small" slot="end">
  <ion-icon slot="icon-only" color="primary" name="cloud-download-outline"></ion-icon>
</ion-button>

</ion-item>
</ion-list>
</div> -->
