/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, isDevMode } from '@angular/core';
// import { StripeService } from 'ngx-stripe';
import { StripePaymentService } from 'src/app/core/services/stripe-payment.service';
import { StripeProduct, StripePrice } from 'src/app/core/models/stripe.products.model';
import { Prices, Price, RootPrice } from 'src/app/core/models/stripe.price.model';
import { StripeCustomerService } from 'src/app/core/services/stripe-customer.service';
import { StripeCustomer } from 'src/app/core/models/stripe.customer.model';
import { isPlatform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { StripeSubscriptionModel } from 'src/app/core/models/stripe.subscription.model';
import { LoadingController } from '@ionic/angular';
import { StripeFirestoreCustomerRecordModel } from 'src/app/core/models/stripe.firestore.customer.record.model';
import { UserSubscriptionService } from 'src/app/core/services/user-subscription.service';
import { environment } from 'src/environments/environment';
import { PaymentProvidersEnum, UserSubscriptionModel } from 'src/app/core/models/user.subscription.model';
import { Purchases, PurchasesOfferings, CustomerInfo, PurchasesEntitlementInfo } from '@awesome-cordova-plugins/purchases/ngx';
import { StripeMembershipService } from 'src/app/core/services/stripe-membership.service';
import { GgtMemberShipService } from 'src/app/core/services/ggt-member-ship.service';

@Component({
  selector: 'app-stripe-subscription-list',
  templateUrl: './stripe-subscription-list.component.html',
  styleUrls: ['./stripe-subscription-list.component.scss'],
})
export class StripeSubscriptionListComponent implements OnInit {
  products: StripeProduct[] = [] as StripeProduct[];
  customer: StripeCustomer;
  stripeId: string;
  stripeSubscriptions: StripeSubscriptionModel[];
  disableSubscribe = false;

  currentClaim: UserSubscriptionModel;
  provider = PaymentProvidersEnum;
  ready = false;

  offerings: PurchasesOfferings;
  purchaseInfo: CustomerInfo;
  loading = false;
  isProduction = environment.production;
  // currentStripeSubscriptions: StripeCurrentSubscriptionModel;

  constructor(
    private stripePaymentSvc: StripePaymentService,
    private stripeCustomerSvc: StripeCustomerService,
    private domSanitizer: DomSanitizer,
    private loadingCtrl: LoadingController,
    private stripeMemSvc: StripeMembershipService,
    private ggtMemberShipSvc: GgtMemberShipService) { }

  ngOnInit() {
    this.getStripeProducts();
    this.getCurrentClaim();

    // TODO Check claim and show proper button to subscribe via store

    // this.getStripeId();
    // this.getCurrentStripeSubscriptions();
    // this.getAvailableStripeSubscriptions();

    // if (this.isCapacitor()) {
    //   this.getPurchaseInfo();
    // }
  }


  async getStripeProducts() {
    const products = await this.stripePaymentSvc.getStripeProducts();
    products.forEach(async product => {
      const ps = await this.stripePaymentSvc.getStripePrices(product.id);
      product.price = ps[0];
      // Check Subscription
      const isSubscribed = await this.ggtMemberShipSvc.isSubscribed(product.id);
      console.log('getStripeProducts.isSubscribed', isSubscribed);
    });

    this.products = products;
  }

  async getCurrentClaim() {
    this.currentClaim = await this.ggtMemberShipSvc.getCurrentAppClaim();
    this.ready = true;
    console.log('current claim', this.currentClaim);
  }

  async manageCapPurchase() {
    this.loading = true;
    const purchased = await this.ggtMemberShipSvc.manageCapPurchase();
    if (purchased) {
      // this.getCurrentClaim();
      this.onClickRefresh();
    }
    this.loading = false;
  }

  async checkCapPurchase() {
    this.loading = true;
    const checked = await this.ggtMemberShipSvc.checkAppStores();
    console.log('checkCapPurchase', checked);
    this.loading = false;
  }

  async clearClaim() {
    await this.ggtMemberShipSvc.clearClaims();
    // this.getCurrentClaim();
    this.ngOnInit();
  }




  // async loadStripeSubscriptions() {
  //   console.log('loadStripeSubscriptions');
  //   this.stripeSubscriptions = await this.stripeCustomerSvc.listCustomerSubscriptionStripe(this.stripeId);
  //   console.log('loadStripeSubscriptions->', this.stripeSubscriptions);
  //   // }
  //   // this.stripeSubscriptions = subModel as StripeSubscriptionModel[];
  //   this.stripeSubscriptions.forEach(subscription => {
  //     this.verifyStripeSubscription(subscription);
  //   });
  // }











  // CLick Form Functions
  async onClickSubscribe(priceId) {
    this.disableSubscribe = true;
    setTimeout(() => {
      this.disableSubscribe = false;
    }, 4000);
    // if (isPlatform('desktop') || isPlatform('mobileweb')) {
    //   if (url !== null) {
    await this.showLoading();
    const url = await this.stripePaymentSvc.getCheckoutProductUrl(priceId);
    window.location.assign(url);
    //   }
    // }
  }

  async capPurchase() {
    // try {
    //   this.offerings = await this.purchases.getOfferings();
    //   const purchase = await this.purchases.purchaseProduct(this.offerings.current.annual.product.identifier);
    //   this.purchaseInfo = purchase.purchaserInfo;
    //   this.getPurchaseInfo(purchase.purchaserInfo);
    //   console.log('purchased', purchase.purchaserInfo);
    // } catch (error) {
    //   console.log('purchase.error', error);
    // }
  }



  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Opening Stripe Secure Payment Portal',
      duration: 5000
    });

    await loading.present();
  }

  // isSubscribed(productId: string) {
  //   // return true;
  //   const isSubscribed = this.stripeCustomerSvc.isSubscribed(productId, this.stripeSubscriptions);
  //   return isSubscribed;
  // }

  isStoreSubscribed() {
    if (this.purchaseInfo) {
      if (this.purchaseInfo.activeSubscriptions[0] === environment.products.subscription) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasActiveClaim() {
    try {
      if (this.currentClaim) {
        return this.currentClaim.active;
      } else {
        return false;
      }
    } catch (error) {
      console.log('No Claim');
      return false;
    }
  }


  onClickMemberShipPortal() {
    this.loading = true;
    this.ggtMemberShipSvc.getMemberShipPortal().then(() => {
      this.loading = false;
    });
  }

  onClickRefresh() {
    this.ngOnInit();
    this.checkCapPurchase();
  }

  isCapacitor() {
    return isPlatform('capacitor');
  }

  isIos() {
    return isPlatform('ios') || isPlatform('ipad') || isPlatform('iphone');
  }

  isAndroid() {
    return isPlatform('android');
  }

  isWebPlatform() {
    return !isPlatform('capacitor');
  }

  private sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

}
