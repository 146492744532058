import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPlatform } from '@ionic/angular';
import { StripeService } from 'ngx-stripe';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StripeCustomer } from '../models/stripe.customer.model';
import { AppInfoService } from './app-info.service';
import { AuthService } from './auth.service';
import { RedirectToCheckoutOptions } from '@stripe/stripe-js';

import { CapacitorHttp, HttpOptions } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class StripeMembershipService {

  constructor(private stripe: StripeService, private http: HttpClient,
    private auth: AuthService, private appInfoSvc: AppInfoService) { }

  async redirectToCheckout() {
    const options: RedirectToCheckoutOptions = {
      mode: 'subscription',
      successUrl: 'https://app.gotgametech.com/success',
      cancelUrl: 'https://app.gotgametech.com/cancel',
    };

    this.stripe.redirectToCheckout(options);
    // const session = await .billingPortal.sessions.create({
    //   customer: 'cus_xxxxxxxxxxxxx',
    //   return_url: 'https://example.com/account',
    // })
  }

  async getCustomerPortalUrl() {
    /** API */
    const url = this.appInfoSvc.apiUrl(`api/create-customer-portal-session`);
    const fbt = await this.currentUserToken();
    const body: any = {
      token: environment.stripePublishKey,
      fbt
    };
    const options: HttpOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: JSON.stringify(body),
      url: url,
    };
    if (isPlatform('capacitor')) {
      return JSON.parse((await CapacitorHttp.post(options)).data);
    } else {
      return lastValueFrom(this.http.post<any>(url, JSON.stringify(body)));
    }
  }

  private async currentUserToken() {
    const token = await this.auth.currentUser().getIdToken();
    return token;
  }
}
