/* eslint-disable @typescript-eslint/naming-convention */

import { PurchasesEntitlementInfo } from '@awesome-cordova-plugins/purchases/ngx';

// userSubscriptions
export interface UserSubscriptionModel {
  id?: string;
  updated_at?: Date;
  name?: string;
  created: number;
  app: string;
  current_period_end: number;
  current_period_start: number;
  active: boolean;
  provider: PaymentProvidersEnum;
}

export enum PaymentProvidersEnum {
  stripe = 'stripe',
  appstore = 'appstore',
  playstore = 'playstore'
}

export enum RevenueCatStoreProvidersEnum {
  stripe = 'STRIPE',
  appstore = 'APP_STORE',
  playstore = 'PLAY_STORE'
}

export interface RevCatUserSubscriptionModel extends PurchasesEntitlementInfo {
  originalPurchaseDateMillis?: number;
  expirationDateMillis?: number;
  latestPurchaseDateMillis?: number;
}
