<ion-app>
  <!-- <ion-split-pane contentId="main-content"> -->
  <ion-menu contentId="main-content" type="overlay" side="end">
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list id="inbox-list">
        <!-- <ion-list-header>Inbox</ion-list-header>
          <ion-note>hi@ionicframework.com</ion-note> -->

        <ion-menu-toggle auto-hide="false">
          <!-- <ion-item lines="full">
              <ion-icon slot="start" name="moon"></ion-icon>
              <ion-label>
                Toggle Dark Theme
              </ion-label>
              <ion-toggle (click)="checkToggle()" slot="end"></ion-toggle>
            </ion-item> -->
          <ion-item *ngFor="let p of appPages; let i = index" routerDirection="root" [routerLink]="[p.url]" lines="none"
            detail="false" routerLinkActive="selected">
            <ion-icon [name]="p.icon"></ion-icon>
            <ion-label class="ion-margin-start">{{ p.title }}</ion-label>
          </ion-item>
          <!-- <ion-item routerDirection="root" (click)="logout()" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="'log-out-outline'" [md]="'log-out-sharp'"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item> -->
        </ion-menu-toggle>
      </ion-list>

      <!-- <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list> -->
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
  <!-- </ion-split-pane> -->
  <!-- <ion-router-outlet id="main-content"></ion-router-outlet> -->
</ion-app>
