import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ContentModel } from '../../models/content.model';
import { VideoModel, VideoItem, VimeoVideo } from '../../models/video.model';
import { VideoService } from '../../services/video.service';
import { File, DirectoryEntry, FileEntry, Entry } from '@awesome-cordova-plugins/file/ngx';
import { ContentService } from '../../services/content.service';
import { LoadingController } from '@ionic/angular';
import { DownloadService } from '../../services/download.service';
import { OfflineManagementService } from '../../services/offline-management.service';


@Component({
  selector: 'app-android-video-player',
  templateUrl: './android-video-player.component.html',
  styleUrls: ['./android-video-player.component.scss'],
})
export class AndroidVideoPlayerComponent implements OnInit {
  @Input() content: ContentModel;
  @Input() videos: VideoModel[];
  videoItems: VideoItem[] = [] as VideoItem[];
  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data: any;
  ready = false;
  downloadedFiles: boolean[] = [] as boolean[];

  // Download
  fileName: string;
  // isDownloaded: boolean;
  downloading: boolean;
  displayProgress: boolean;
  aborted: boolean;
  loadProgress: number;
  percentage = 0;

  constructor(private videoSvc: VideoService, private ngZone: NgZone,
    private file: File, private contentService: ContentService, private loading: LoadingController,
    private downloadSvc: DownloadService, private offlineManageSvc: OfflineManagementService) {
  }
  ngOnInit(): void {
    console.log('Android Videos');
    // console.log('VideogularPlayerComponent.videos', this.videos);
    this.loadVideos();
  }

  async isDownloaded(index: number) {
    const video = this.videos[index];
    const filename = this.contentService.getVideoFileName(video.id);
    const file = await this.downloadSvc.downloadedVideoFile(filename);
    return file.isFile ? file.isFile : false;
  };

  async loadVideos() {
    this.ready = false;
    this.downloadedFiles = [] as boolean[];
    this.videoItems = [];
    this.videos.forEach(async (video, index) => {
      const filename = this.contentService.getVideoFileName(video.id);
      console.log('loadVideos.filename', video.id, filename);
      const downloaded = await this.downloadSvc.downloadedVideoFile(filename);
      console.log('loadVideos.downloaded', downloaded);
      let link = '';
      if (downloaded) {
        console.log('loadVideos.fileDownloaded', downloaded);
        link = Capacitor.convertFileSrc(downloaded.nativeURL);
        this.downloadedFiles[index] = true;
      } else {
        console.log('loadVideos.file not downloaded');
        const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
        link = this.videoSvc.getHdVideoUrl(vimeo.files).link;
      }

      // console.log('vimeo.src', src);
      const vid: VideoItem = {
        name: video.name,
        src: link,
        type: 'video/mp4'
      };
      // console.log('video.item', vid);
      this.videoItems.push(vid);
      this.currentVideo = this.videoItems[this.activeIndex];
      console.log('loadVideos.videoItems', this.videoItems);
      this.ready = true;
    });

  }

  videoPlayerInit(data: any) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
    this.data.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
  nextVideo() {
    this.activeIndex++;
    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }
    this.currentVideo = this.videoItems[this.activeIndex];
  }
  initVdo() {
    this.data.play();
  }
  startPlaylistVdo(item: any, index: number) {
    this.activeIndex = index;
    this.currentVideo = item;
  }

  async presentLoading() {
    const loading = await this.loading.create({
      message: 'Please wait for video to be downloaded to your device.',
      duration: 0,
      backdropDismiss: true
    });
    await loading.present();
  }

  async removeVideoDownload(videoItem: VideoItem, index: number) {
    this.ready = false;
    const video = this.videos[index];
    this.offlineManageSvc.removeVideoDownload(video.id);
    this.loadVideos();
  }

  async download(videoItem: VideoItem, index: number) {
    console.log('download.videoItem', videoItem);
    console.log('download.videoItem.index', index);
    const video = this.videos[index];
    console.log('video', video);
    this.ready = false;
    this.downloading = true;
    this.displayProgress = true;
    this.presentLoading();
    const filename = this.contentService.getVideoFileName(video.id);
    console.log('FileName ->', filename);
    // const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
    const file = await this.offlineManageSvc.setVideoDownload(video.id, video.vimeoId);
    // const file: FileEntry = await this.downloadSvc.downloadFile(filename, videoItem.src);
    if (file) {
      // this.getDownloadedFiles();
      this.downloading = false;
      this.loadVideos();
      this.loading.dismiss();
      // this.isDownloaded = true;
    }
    console.log('Download File->', file);
  }
}
