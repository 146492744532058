<ion-item lines="none">
  <!-- <ion-icon name="play" slot="start"></ion-icon> -->
  <ion-thumbnail slot="start" id="open-modal">
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==">
  </ion-thumbnail>

  <ion-label>
    <h2>{{video?.name}}</h2>
  </ion-label>
  <ion-icon slot="end" name="ellipsis-vertical-outline"></ion-icon>
</ion-item>
<!-- (click)="presentActionSheet()" -->
<ion-modal trigger="open-modal" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="primary" fill="clear" (click)="cancel()">Cancel</ion-button>
        </ion-buttons>
        <ion-title>Welcome</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-content fullscreen>
      <!-- <ion-item> -->
      <app-videogular-player></app-videogular-player>
      <!-- </ion-item> -->
      <ion-button color="primary" fill="clear" (click)="cancel()">Cancel</ion-button>
    </ion-content>
  </ng-template>
</ion-modal>
