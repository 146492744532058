import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ListedItemComponent } from './components/listed-item/listed-item.component';
import { ContentFilterPipe } from './pipes/content-filter.pipe';
import { ListedVideoItemComponent } from './components/listed-video-item/listed-video-item.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

import { VgCoreModule, VgPlayerComponent } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VideogularPlayerComponent } from './components/videogular-player/videogular-player.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { AudioRecorderComponent } from './components/audio-recorder/audio-recorder.component';
import { AudioRecorderAndroidComponent } from './components/audio-recorder-android/audio-recorder-android.component';
import { AudioRecorderIosComponent } from './components/audio-recorder-ios/audio-recorder-ios.component';
import { IonicModule } from '@ionic/angular';
import { NgxStripeModule } from 'ngx-stripe';

import { Media } from '@awesome-cordova-plugins/media/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Purchases } from '@awesome-cordova-plugins/purchases/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';



import { VideoCardPreviewComponent } from './components/video-card-preview/video-card-preview.component';
import { AudioCardPreviewComponent } from './components/audio-card-preview/audio-card-preview.component';
import { TipCardPreviewComponent } from './components/tip-card-preview/tip-card-preview.component';
import { InstaFeedSuccessComponent } from './components/insta-feed-success/insta-feed-success.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';

import { AndroidVideoPlayerComponent } from './components/android-video-player/android-video-player.component';
import { IosVideoPlayerComponent } from './components/ios-video-player/ios-video-player.component';


import { ListedFavoritesComponent } from './components/listed-favorites/listed-favorites.component';
import { ListedWordpressItemsComponent } from './components/listed-wordpress-items/listed-wordpress-items.component';
import { StripeSubscriptionListComponent } from './components/stripe/stripe-subscription-list/stripe-subscription-list.component';
import { CustomerBillingInformationComponent } from
  './components/stripe/customer-billing-information/customer-billing-information.component';
import { CapPurchasesComponent } from 'src/app/core/components/subscriptions/cap-purchases/cap-purchases.component';
import { ImgFallbackThumb1Directive } from './directives/img-fallback-thumb1.directive';
import { ImgFallbackCard2Directive } from './directives/img-fallback-card2.directive';
import { AboutTypePipe } from './pipes/about-type.pipe';
// import { SingleMediaPlayer } from './single-media-player';



@NgModule({
  declarations: [
    ListedItemComponent,
    ListedWordpressItemsComponent,
    ListedVideoItemComponent,
    ContentFilterPipe,
    VideoPlayerComponent,
    VideogularPlayerComponent,
    AudioPlayerComponent,
    AudioRecorderComponent,
    AudioRecorderAndroidComponent,
    AudioRecorderIosComponent,
    VideoCardPreviewComponent,
    AudioCardPreviewComponent,
    TipCardPreviewComponent,
    ListedFavoritesComponent,
    StripeSubscriptionListComponent,
    CustomerBillingInformationComponent,
    ImgFallbackThumb1Directive,
    ImgFallbackCard2Directive,
    AboutTypePipe,
    InstaFeedSuccessComponent,
    AndroidVideoPlayerComponent,
    IosVideoPlayerComponent,
    CapPurchasesComponent,
    TermsOfUseComponent
  ],
  exports: [
    ListedItemComponent,
    ListedWordpressItemsComponent,
    ListedVideoItemComponent,
    ContentFilterPipe,
    AboutTypePipe,
    VideoPlayerComponent,
    VideoCardPreviewComponent,
    AudioCardPreviewComponent,
    TipCardPreviewComponent,
    VideogularPlayerComponent,
    AudioPlayerComponent,
    AudioRecorderComponent,
    AudioRecorderAndroidComponent,
    AudioRecorderIosComponent,
    ListedFavoritesComponent,
    StripeSubscriptionListComponent,
    CustomerBillingInformationComponent,
    InstaFeedSuccessComponent,
    AndroidVideoPlayerComponent,
    IosVideoPlayerComponent,
    CapPurchasesComponent,
    TermsOfUseComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    // BrowserModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    RouterModule,
    NgxStripeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    Media,
    MediaCapture,
    File,
    Purchases,
    InAppPurchase2,
    HTTP,
    StreamingMedia
  ],

  bootstrap: [
    VideogularPlayerComponent,
    AndroidVideoPlayerComponent,
    IosVideoPlayerComponent,
    AudioPlayerComponent,
    AudioRecorderComponent,
    AudioRecorderAndroidComponent,
    AudioRecorderIosComponent,
    TermsOfUseComponent
  ]
})
export class SharedModule { }
