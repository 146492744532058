/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
// import { Stripe } from '@capacitor-community/stripe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  setting = window.matchMedia('(prefers-color-scheme: dark)');
  my_dark_mode_flag = this.setting.matches;

  public appPages = [
    { title: 'Account', url: '/pages/account', icon: 'person-outline' },
    { title: 'Products', url: '/pages/products', icon: 'gift-outline' },
    { title: 'Favorites', url: '/pages/favorites', icon: 'heart-outline' },
    // { title: 'Notifications', url: '/pages/notifications', icon: 'notifications-outline' },
    { title: 'Downloads', url: '/pages/offline-management', icon: 'download-outline' },
    { title: '#ElkNutSuccess Photos', url: '/pages/insta-feed-success', icon: 'images-outline' },
    { title: 'Intro Videos', url: '/pages/howto', icon: 'videocam-outline' },
    { title: 'About', url: '/pages/about', icon: 'information-circle-outline' },
    { title: 'Logout', url: '/logout', icon: 'log-out-outline' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private auth: AuthService) {
    // Stripe.initialize({
    //   publishableKey: environment.stripePublishKey,
    // });

  }

  logout() {
    this.auth.logout();
  }
  checkToggle() {
    this.my_dark_mode_flag = !this.my_dark_mode_flag;
    if (this.my_dark_mode_flag) {
      document.body.classList.toggle('dark');
    } else {
      document.body.classList.toggle('light');
    }
    // document.body.classList.toggle('dark', this.my_dark_mode_flag);
    console.log('toggle', this.my_dark_mode_flag);
  }

}
