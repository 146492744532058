import { Injectable } from '@angular/core';
import { Firestore, onSnapshot, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { UserSubscriptionModel } from '../models/user.subscription.model';
import { AppInfoService } from './app-info.service';
// import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { isPlatform } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';

import { CapacitorHttp, HttpOptions } from '@capacitor/core';

// import { doc, onSnapshot } from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService {
  userSubscription: UserSubscriptionModel;
  constructor(private fs: Firestore, private appInfoSvc: AppInfoService, private userSvc: UserService,
    private http: HttpClient) { }

  get isActiveSubscription() {
    const active = localStorage.getItem(`${environment.app}.subscription`) === 'true' ? true : false;
    // console.log('isActiveSubscription.active', active);
    return active;
  }

  async verifyStripeSubscription(uid: string) {
    const docRef = doc(this.fs, 'userSubscriptions', uid);
    return (await getDoc(docRef)).data();
  }

  async createStripeSubscription(uid: string, userSubscription: UserSubscriptionModel) {
    await setDoc(doc(this.fs, 'userSubscriptions', uid), userSubscription);
  }

  async createClaimSubscription(user: UserSubscriptionModel) {
    console.log('Creating Custom Claim', user);
    const url = this.appInfoSvc.apiUrl(`api/add-claim-subscription`);
    const uid = await (await this.userSvc.getCurrentUser()).uid;
    const body: any = {
      uid,
      user,
      app: environment.app
    };
    const options: HttpOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: JSON.stringify(body),
      url: url,
    };
    console.log('createClaimSubscription', body);
    // Check Platform
    if (isPlatform('capacitor')) {
      return JSON.parse((await CapacitorHttp.post(options)).data);
    } else {
      return lastValueFrom(this.http.post<any>(url, JSON.stringify(body)));
    }

  }

  async removeClaimSubscription() {
    console.log('Remove Custom Claim');
    const url = this.appInfoSvc.apiUrl(`api/remove-claim-subscription`);
    const uid = await (await this.userSvc.getCurrentUser()).uid;
    const body: any = {
      uid,
      app: environment.app
    };
    const options: HttpOptions = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: JSON.stringify(body),
      url: url,
    };
    console.log('removeClaimSubscription', body);
    if (isPlatform('capacitor')) {
      return CapacitorHttp.post(options);
    } else {
      return lastValueFrom(this.http.post<any>(url, JSON.stringify(body)));
    }
  }

  async createAppStoreSubscription(uid: string, userSubscription: UserSubscriptionModel) {
    await setDoc(doc(this.fs, 'userSubscriptions', uid), userSubscription);
  }

  getSubscribedUser() {
    return this.userSubscription;
  }



  // private async currentUserToken() {
  //   const token = await this.auth.currentUser().getIdToken();
  //   return token;
  // }
}
