import { Component, Input, NgZone, OnInit, SecurityContext, OnDestroy } from '@angular/core';

import { ContentModel } from '../../models/content.model';
import { VideoModel, VideoItem, VimeoVideo } from '../../models/video.model';
import { VideoService } from '../../services/video.service';
import { File, Entry } from '@awesome-cordova-plugins/file/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@awesome-cordova-plugins/streaming-media/ngx';

import { ContentService } from '../../services/content.service';
import { LoadingController } from '@ionic/angular';
import { DownloadService } from '../../services/download.service';
import { OfflineManagementService } from '../../services/offline-management.service';
import { Filesystem } from '@capacitor/filesystem';
import { Location } from '@angular/common';
import { FavoritesService } from '../../services/favorites.service';
import { DataType } from '../../models/data.type.model';

// import write_blob from 'capacitor-blob-writer';

@Component({
  selector: 'app-ios-video-player',
  templateUrl: './ios-video-player.component.html',
  styleUrls: ['./ios-video-player.component.scss'],
})
export class IosVideoPlayerComponent implements OnInit, OnDestroy {
  @Input() content: ContentModel;
  @Input() videos: VideoModel[];
  videoItems: VideoItem[] = [] as VideoItem[];
  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data: any;
  ready = false;
  downloadedFiles: string[] = [] as string[];
  show = true;
  isFavorite = false;
  // played = false;

  // Download
  fileName: string;
  // isDownloaded: boolean;
  downloading: boolean;
  displayProgress: boolean;
  aborted: boolean;
  loadProgress: number;
  percentage = 0;

  isTrainer = (this.location.path().split('/')[1] === 'trainer') ? true : false;

  constructor(private videoSvc: VideoService, private ngZone: NgZone, private streamingMedia: StreamingMedia,
    private file: File, private contentService: ContentService, private loading: LoadingController,
    private downloadSvc: DownloadService, private offlineManageSvc: OfflineManagementService,
    private location: Location, private favoriteSvc: FavoritesService) {
  }
  ngOnInit(): void {
    console.log('Ios Videos');
    // console.log('VideogularPlayerComponent.videos', this.videos);
    this.loadVideos();
    this.checkFavorite();
  }

  ngOnDestroy() {
    this.ready = false;
  }

  async checkFavorite() {
    console.log('checkFavorite.id', this.content.id, this.content.name);
    this.isFavorite = await this.favoriteSvc.isFavoriteAsync(this.content.id, DataType.content);
    console.log('checkFavorite', this.isFavorite);
  }

  async removeFavorite() {
    // await this.favoriteSvc.removeFavorite(favorite);
    console.log('remove Favorite->', this.content);
    await this.favoriteSvc.removeFavoriteByRefId(this.content.id);
    this.checkFavorite();
  }


  isDownloaded(index: string) {
    const filtered = this.downloadedFiles.filter((id) => id === index);
    return filtered[0] ? true : false;
  };

  getName(id: string) {
    const filtered = this.videos.filter((video) => video.id === id);
    return filtered[0].name;
  }

  async loadVideos() {
    this.ready = false;
    this.show = true;
    this.downloadedFiles = [] as string[];
    this.videoItems = [];
    this.videos.forEach(async (video, index) => {
      const filename = this.contentService.getVideoFileName(video.id);
      console.log('loadVideos.filename', video.id, filename);
      const downloaded = await this.downloadSvc.downloadedVideoFile(filename);
      console.log('loadVideos.downloaded', downloaded);
      let link = '';
      if (downloaded) {
        console.log('loadVideos.fileDownloaded', downloaded);
        this.show = false;
        link = downloaded.name;
        this.downloadedFiles.push(video.id);
        // this.downloadedFiles[index] = true;
        if (this.videos.length < 1) {
          this.playOffline(link, index);
        }
      } else {
        console.log('loadVideos.file not downloaded');
        const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
        link = this.videoSvc.getHdVideoUrl(vimeo.files).link;
      }

      // console.log('vimeo.src', src);
      const vid: VideoItem = {
        name: video.id,
        src: link,
        type: 'video/mp4'
      };
      // console.log('video.item', vid);
      this.videoItems.push(vid);
      this.currentVideo = this.videoItems[this.activeIndex];
      console.log('loadVideos.videoItems', this.videoItems);
      this.ready = true;
    });

  }

  videoPlayerInit(data: any) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
    this.data.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
  nextVideo() {
    this.activeIndex++;
    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }
    this.currentVideo = this.videoItems[this.activeIndex];
  }
  initVdo() {
    this.data.play();
  }
  startPlaylistVdo(item: any, index: number) {
    console.log('Play Stream');
    this.show = true;
    this.activeIndex = index;
    // this.currentVideo = item;
    this.activeIndex = index;
    this.currentVideo = this.videoItems[this.activeIndex];
  }

  async presentLoading() {
    const loading = await this.loading.create({
      message: 'Please wait for video to be downloaded to your device.',
      duration: 0,
      backdropDismiss: true
    });
    await loading.present();
  }

  async removeVideoDownload(videoItem: VideoItem, index: number) {
    console.log('removeVideoDownload', videoItem);
    this.ready = false;
    const video = this.videos[index];
    this.offlineManageSvc.removeVideoDownload(video.id);
    this.loadVideos();
  }

  async download(videoItem: VideoItem, index: number) {
    console.log('download.videoItem', videoItem);
    console.log('download.videoItem.index', index);
    const video = this.videos[index];
    console.log('video', video);
    this.ready = false;
    this.downloading = true;
    this.displayProgress = true;
    this.presentLoading();
    const filename = this.contentService.getVideoFileName(video.id);
    console.log('FileName ->', filename);
    // const vimeo = await this.videoSvc.getVimeoVid(video.vimeoId);
    const file = await this.offlineManageSvc.setVideoDownload(video.id, video.vimeoId);
    // const file: FileEntry = await this.downloadSvc.downloadFile(filename, videoItem.src);
    if (file) {
      // this.getDownloadedFiles();
      this.downloading = false;
      this.loadVideos();
      this.loading.dismiss();
      // this.isDownloaded = true;
    }
    console.log('Download File->', file);
  }

  async playOffline(src: string, index: number) {
    console.log('playOffline');
    this.show = false;
    const path = await this.downloadSvc.downloadedVideoFile(src);

    const options: StreamingVideoOptions = {
      successCallback: () => { console.log('Video played'); },
      errorCallback: (e) => { console.log('Error streaming'); },
      // orientation: 'landscape',
      shouldAutoClose: true,
      controls: true,
    };
    this.streamingMedia.playVideo(path.nativeURL, options);
    this.activeIndex = index;
    this.currentVideo = this.videoItems[this.activeIndex];
  }

}
