export enum DataType {
  about = 'about',
  apps = 'apps',
  audio = 'audio',
  content = 'content',
  howto = 'howto',
  notes = 'notes',
  favorites = 'favorites',
  products = 'products',
  tips = 'tips',
  users = 'users',
  video = 'video'
}
