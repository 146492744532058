/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatform } from '@ionic/angular';

import {
  CollectionReference, DocumentReference, query, where, getDocs,
  getFirestore, collection, doc, DocumentData, collectionData, Firestore
} from '@angular/fire/firestore';
import { PublishStatus } from '../models/status.model';
import { ContentType } from '../models/content.type.model';
import { VideoModel } from '../models/video.model';
import { ContentModel, ContentExtendedModel } from '../models/content.model';
import { TierType } from '../models/tier.types.model';

export interface Content {
  id: string;
  name: string;
  type: string;
  tier: string;
  updated_at?: string;
  description: string;
  videoFile?: string;
  app: string;
  status: string;
  productId?: string;
  parentId?: string;
  subTactics?: Array<Content>;
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  content: ContentModel[] = [] as ContentModel[];
  extendedContent: ContentExtendedModel[] = [] as ContentExtendedModel[];
  contentRef: DocumentData = {} as DocumentData;
  tier = TierType;
  // private contentCollection: CollectionReference<Content>;
  constructor(private fs: Firestore) {
  }

  async getCalls() {
    const q = query(collection(this.fs, 'content'), where('app', '==', environment.app), where('type', '==', 'main'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      this.content[d.id] = d.data();
    });
    return this.content;
  }

  async getContent() {
    const q = query(collection(this.fs, 'content'),
      where('app', '==', environment.app),
      where('status', '==', PublishStatus.published)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      // this.content[d.id] = d.data();
      this.content.push(d.data() as ContentModel);
    });
    return this.content;
  }

  // where('type', '==', type),

  async getContentByType(type: ContentType) {
    const q = query(collection(this.fs, 'content'),
      where('app', '==', environment.app),
      where('type', '==', type),
      where('status', '==', PublishStatus.published)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (d) => {
      // this.content[d.id] = d.data();
      let content = d.data() as ContentModel;
      content.id = d.id;
      this.content.push(content);

    });
    return this.content;
  }

  async getContentVideos(contentId: string) {
    // eslint-disable-next-line prefer-const
    // console.log('getContentVideos.contentId->', contentId);
    let videos: VideoModel[] = {} as VideoModel[];
    const qs = query(collection(this.fs, 'videos'),
      where('app', '==', environment.app),
      where('status', '==', PublishStatus.published),
      where('contentId', '==', contentId)
    );
    // console.log('getContentVideos.qs->', qs);
    const subQuerySnapshot = await getDocs(qs);
    // return getDocs(qs);
    subQuerySnapshot.forEach((s) => {
      // console.log('getContentVideos.vid =>', s.id);
      let video = s.data() as VideoModel;
      video.id = s.id;
      videos.push(video);
    });
    return videos;
  }

  getVideoFileName(videoFile: string) {
    return videoFile + '.mp4';
  }

  getAudioFileName(id: string, audioFile: string = null) {
    return `${id}.${this.getAudioExtension(audioFile)}`;
  }

  isItemPro(item: ContentModel): boolean {
    if (item) {
      if (item.tier == this.tier.pro) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Return Extension
  private getAudioExtension(audioFile: string = null) {
    if (audioFile != null) {
      const a = audioFile.split('.');
      const ext = a.pop();
      console.log('getAudioExtension', ext);
      return ext;
    } else {
      return 'mp3';
    }
  }

}
