<!-- <ion-card>
  <ion-card-header>
    <ion-card-subtitle>Latest ElkNut News</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    Keep close to Nature's heart... and break clear away, once in awhile,
    and climb a mountain or spend a week in the woods. Wash your spirit clean.
  </ion-card-content>
</ion-card> -->
<ion-list class="ion-padding-start ion-padding-end">
  <ion-list-header color="dark">
    <h3>Latest {{appName}} News</h3>
  </ion-list-header>
  <ion-item detail="true" button *ngFor="let post of posts" (click)="onOpen(post)">
    <ion-label class="ion-text-wrap" [innerHTML]="post.title.rendered"></ion-label>
    <!-- <ion-buttons slot="end">
      <ion-button fill="clear" size="large" expand="block" (click)="onOpen(post)">
        <ion-icon slot="icon-only" name="document-text-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" size="large" expand="block" (click)="openUrl(post.link)">
        <ion-icon slot="icon-only" name="link-outline"></ion-icon>
      </ion-button>
    </ion-buttons> -->

  </ion-item>
</ion-list>
<ion-modal (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="onCancel()">Cancel</ion-button>
        </ion-buttons>
        <ion-title [innerHTML]="currentPost.title.rendered"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [innerHTML]="currentPost.content.rendered">
    </ion-content>
    <ion-footer>

      <ion-row>
        <ion-col center text-center>
          <ion-button (click)="onCancel()" color="light" expand="block">
            <ion-icon name="close-outline"></ion-icon>
            CLOSE
          </ion-button>
        </ion-col>
        <ion-col center text-center>
          <ion-button (click)="openUrl(currentPost.link)" color="light" expand="block">
            <ion-icon slot="start" name="link-outline"></ion-icon>
            VISIT SITE
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ng-template>
</ion-modal>
