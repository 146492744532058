import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Platform, isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor() { }

  get appType(): string {
    return environment.type;
  }

  get proName(): string {
    return environment.pro;
  }

  get appName(): string {
    return environment.appName;
  }

  openUrl(url: string) {
    // if (isPlatform('desktop') || isPlatform('mobileweb')) {

    // }
    window.open(url);
    // this.iab.create(url);
  }

  apiUrl(url: string) {
    if (isPlatform('capacitor') || isPlatform('cordova')) {
      console.log('apiUrl.isProduction', environment.production);
      return `${environment.functionApiUrl}/${url}`;
    } else {
      return url;
    }
  }
}
