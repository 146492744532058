<ion-item button lines="none" border-radius="5px" (click)="view()">
  <!-- <div [routerLink]="['./', item.id]" router-direction="forward"> -->
  <ion-thumbnail slot="start">
    <!-- <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw=="> -->
    <!-- <ion-img *ngIf="item" [src]="getImage(item?.id)"></ion-img> -->
    <img *ngIf="item" [src]="getImage(item?.id)" appImgFallbackThumb1>
    <!-- <picture>
      <source [srcset]="getImage(item?.id)" type="image/png" />
      <img src="/assets/img/default/Thumb@1x.png">
    </picture> -->
  </ion-thumbnail>
  <ion-label>
    <h3 class="ion-text-wrap"> {{item?.name}}
      <ion-text color="primary" *ngIf="isItemPro">Pro</ion-text>
      <ion-icon *ngIf="isFavorite" name="heart" slot="end" color="danger"></ion-icon>
      <!-- <span class="pro-label">Pro</span> -->
    </h3>
    <div class="ion-text-wrap" *ngIf="showDetail" [innerHTML]="item?.description">
      <!-- <span [innerHTML]="item?.description"></span> -->
    </div>
  </ion-label>
  <!-- </div> -->
  <!-- <ion-chip slot="end" color="primary" *ngIf="isItemPro()">
    <ion-label>Pro</ion-label>
  </ion-chip> -->
  <ion-button (click)="presentActionSheet($event)" fill="clear" slot="end" color="dark">
    <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
  </ion-button>

</ion-item>

<!-- [routerLink]="['./', item.id]" router-direction="forward" -->
<!-- [routerLink]="['./', item.id]" router-direction="forward" -->