/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import {
  query, collection, where, getDocs, Firestore, collectionData, onSnapshot, doc, setDoc,
  addDoc, deleteDoc
} from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import {
  getStorage, ref, uploadBytes, UploadResult, getDownloadURL,
  deleteObject, listAll, list
} from '@angular/fire/storage';
import { DocStatus } from '../models/doc.status.model';

import { PracticeAudioModel } from 'src/app/core/models/practice-audio.model';
import { Auth } from '@angular/fire/auth';
import { Observable } from 'rxjs/internal/Observable';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PracticeAudioService {
  practices$: Observable<PracticeAudioModel[]>;
  practices: PracticeAudioModel[];
  private key = 'practice';
  private storageUrl = `https://storage.googleapis.com/${environment.firebase.storageBucket}`;
  constructor(private fs: Firestore, private auth: Auth, private toastController: ToastController) {
    const q = query(collection(this.fs, this.key),
      where('app', '==', environment.app),
      where('user', '==', this.auth.currentUser.uid),
    );
    this.practices$ = collectionData(q, { idField: 'id' }) as Observable<PracticeAudioModel[]>;
  }


  async getPracticeAudioByContent(contentId: string) {
    console.log('getPracticeAudioByContent');
    const q = query(collection(this.fs, this.key),
      where('app', '==', environment.app),
      where('user', '==', this.auth.currentUser.uid),
      where('content', '==', contentId)
    );
    const querySnapshot = await getDocs(q);
    console.log('querySnapshot->', querySnapshot);
    let practices: PracticeAudioModel[] = [] as PracticeAudioModel[];
    querySnapshot.forEach((d) => {
      console.log('snap->', d);
      let p: PracticeAudioModel = d.data() as PracticeAudioModel;
      p.id = d.id;
      practices.push(p);
    });
    return practices;
  }

  async addPractice(practice: PracticeAudioModel, file: File) {
    const practiceRef = doc(collection(this.fs, this.key));
    practice.user = this.auth.currentUser.uid;
    console.log('addPractice', practiceRef);
    console.log('addPractice.practice', practice);
    const result = await this.uploadFile(practiceRef.id, file);
    console.log('addPractice.uploadFile.result', result);
    practice.file = result;
    // practice.file = `${this.storageUrl}/${this.key}/${practiceRef.id}/${file.name}`;
    await setDoc(practiceRef, practice);
  }

  async removePractice(id: string) {
    await deleteDoc(doc(this.fs, this.key, id));
    this.removeFile(id);
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private uploadFile(id: string, file: File) {
    const storage = getStorage();
    const name = `${this.key}/${id}/${file.name}`;
    const storageRef = ref(storage, name);
    let url = '';
    return uploadBytes(storageRef, file).then((snapshot) => getDownloadURL(snapshot.ref).then((downloadUrl) => {
      url = downloadUrl;
      console.log('Download url', url);
      return url;
    }));
  }

  private removeFile(id: string) {
    const storage = getStorage();
    const f = `${this.key}/${id}`;
    console.log('store f name', f);
    const listRef = ref(storage, f);
    console.log('listRef', listRef);

    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          console.log('List Item', itemRef);
          deleteObject(itemRef).then(() => {
            // File deleted successfully
            this.openToast('Deleted the Recording');
            console.log('Success Delete');
          }).catch((error) => {
            this.openToast('Problem Deleting the Recording');
            console.log('Fail Delete', error);
            // Uh-oh, an error occurred!
          });
        });
      }).catch((error) => {
        this.openToast('Problem Finding the Recording');
        console.log('Fail Load', error);
        // Uh-oh, an error occurred!
      });

  }


  private openToast(message: string) {
    this.toastController.create({
      color: 'dark',
      duration: 5000,
      message
    }).then((toastData) => {
      toastData.present();
    });
  }


}
