/* eslint-disable eqeqeq */
import { Component, Input, OnInit } from '@angular/core';
import { ContentModel } from '../../models/content.model';
import { TierType } from '../../models/tier.types.model';
import { VideoModel } from '../../models/video.model';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-video-card-preview',
  templateUrl: './video-card-preview.component.html',
  styleUrls: ['./video-card-preview.component.scss'],
})
export class VideoCardPreviewComponent implements OnInit {
  @Input() content: ContentModel;
  @Input() videos: VideoModel[];
  ready = false;
  tier = TierType;
  constructor(private contentSvc: ContentService) { }

  get thumbnail() {
    // console.log('thumb', this.videos[0].thumbnail);
    if (this.videos) {
      // console.log('thumb', this.videos[0].thumbnail);
      return '/assets/img/' + this.content.id + '/Card@2x.png';
      // return this.videos[0].thumbnail;
    } else {
      return '/assets/img/river.jpg';
    }
  }

  isItemPro(): boolean {
    return this.contentSvc.isItemPro(this.content);
  }

  getImage(id: string) {
    return '/assets/img/' + id + '/Card@2x.png';
  }
  ngOnInit() {
    // console.log('VideoCardPreviewComponent', this.content, this.videos);

    if (this.content) {
      this.ready = true;
    }
  }



}


// Vimeo Player
// https://www.npmjs.com/package/@types/vimeo__player
// https://www.npmjs.com/package/@vimeo/player


// NGX Player
// https://smnbbrv.github.io/ngx-plyr/
