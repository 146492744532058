import { Component, OnInit } from '@angular/core';
import { Platform, isPlatform } from '@ionic/angular';
import { Purchases, PurchasesOfferings, CustomerInfo } from '@awesome-cordova-plugins/purchases/ngx';
import { InAppPurchase2, IAPProductOptions } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cap-purchases',
  templateUrl: './cap-purchases.component.html',
  styleUrls: ['./cap-purchases.component.scss'],
})
export class CapPurchasesComponent implements OnInit {
  offerings: PurchasesOfferings;
  purchaseInfo: CustomerInfo;
  constructor(public platform: Platform, private purchases: Purchases,
    private store: InAppPurchase2) {
  }

  ngOnInit() {
    this.getPurchaseInfo();
  }

  async getPurchaseInfo() {
    this.purchaseInfo = await this.purchases.getCustomerInfo();
    this.offerings = await this.purchases.getOfferings();;
    console.log('purchaseInfo.purchase', this.purchaseInfo);
    console.log('purchaseInfo.offerings', this.offerings);
  }

  onPurchaseUpdate() {
    this.purchases.onCustomerInfoUpdated().subscribe(info => {
      this.purchaseInfo = info;
      console.log('purchase info updated', info);
    });
  }

  // async storeInfo() {
  //   this.platform.ready().then(() => {
  //     this.store.register({
  //       id: environment.products.subscription,
  //       type: this.store.PAID_SUBSCRIPTION,
  //     });
  //     console.log('store.products', this.store.products);
  //     this.store.when(environment.products.subscription)
  //       .approved(p => {
  //         console.log('verify.p', p);
  //         p.verify();
  //       })
  //       .verified(p => {
  //         console.log('verified.p', p);
  //         p.finish();
  //       });
  //     this.store.refresh();
  //   });
  // }

  async order() {
    // await this.store.order(environment.products.pro);
    await this.store.order(environment.products.subscription);
  }

  async purchase() {
    const purchase = await this.purchases.purchaseProduct(this.offerings.current.annual.product.identifier);
    console.log('purchase', purchase);
  }

}
