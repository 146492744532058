import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insta-feed-success',
  templateUrl: './insta-feed-success.component.html',
  styleUrls: ['./insta-feed-success.component.scss'],
})
export class InstaFeedSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
