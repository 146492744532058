<audio #media vg-type="audio" [src]="audio?.audioFile" type="audio/mpeg">
</audio>
<ion-card class="ion-no-padding">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding">
      <ion-col class="ion-align-self-start player-control-skip ion-no-padding">
        <ion-button [disabled]="isPlayDisabled()" (click)="back()" fill="clear" color="dark" size="large">
          <ion-icon name="play-skip-back"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-align-self-center player-control-play ion-no-padding">
        <ion-button [disabled]="isPlayDisabled()" *ngIf="!playing" (click)="play()" fill="clear" color="dark"
          size="large">
          <ion-icon slot="icon-only" name="play"></ion-icon>
        </ion-button>
        <ion-button *ngIf="playing" (click)="pause()" fill="clear" color="dark" size="large">
          <ion-icon slot="icon-only" name="pause"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-align-self-end player-control-skip ion-no-padding">
        <ion-button [disabled]="isPlayDisabled()" (click)="forward()" fill="clear" color="dark" size="large">
          <ion-icon name="play-skip-forward"></ion-icon>
        </ion-button>

      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding">
      <ion-col class="ion-padding-start ion-no-padding">
        <div class="ion-text-start time">{{currentTime | number }}</div>
      </ion-col>
      <ion-col>
        <ion-button (click)="onClickLoop()" fill="clear" color="dark" size="small">
          <ion-icon *ngIf="loop" slot="icon-only" name="infinite"></ion-icon>
          <ion-icon *ngIf="!loop" color="light" slot="icon-only" name="infinite"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-padding-end ion-no-padding">
        <div class="ion-text-end time" *ngIf="duration !== 0">{{duration | number }}</div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-progress-bar expand="full" color="primary" [value]="progress"></ion-progress-bar>
    </ion-row>
  </ion-grid>
</ion-card>
